<template>
  <div class="ex-balance">
    <portal to="toolbar__title">Balances</portal>
    <div class="ex-balance__content">
      <div class="ex-balance__header">
        <div class="ex-balance__filters">
          <el-select v-model="currencyType" placeholder="Filter currencies">
            <el-option v-for="item in optionsCurrencies" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <ex-report type="Balance" :value="{filters: {dateEnd: date}}">
            Get PDF Report
          </ex-report>
        </div>
        <el-form class="ex-balance__date" label-position="left">
          <el-form-item label="Historical Balance">
            <el-date-picker
              v-model="date"
              type="datetime"
              placeholder="Select date and time"
              default-time="12:00:00"
              size="large"
              :editable="false"
              @change="applyTableFilter"
            />
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="balancesFilteredByCurrencyType">
        <el-table-column label="Currency" min-width="80px">
          <template #header>
            <span class="ex-balance__first-column-label">Currency</span>
          </template>
          <template v-slot="{row}">
            <img
              :src="getCurrency(row.currency).icon"
              alt="icon"
              class="ex-balance__icon"
              :class="{'ex-balance__icon_blur': SPECIAL_CURRENCIES_LIST.includes(row.currency)}"
            />
            <span>{{row.currency}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Amount" min-width="80px">
          <template v-slot="{row}">
            {{formatCurrency(row.amount, row.type, isTONLike(row.currency) ? 9 : null)}}
          </template>
        </el-table-column>
        <el-table-column min-width="150px">
          <template #header>
            <span class="ex-balance__third-column-label">Actions</span>
          </template>
          <template v-slot="{row}">
            <div v-if="row.type === CURRENCY_TYPE.CRYPTO" class="ex-balance__actions">
              <el-button
                class="ex-balance__action"
                type="warning"
                :disabled="CURRENCIES_DISCONNECTED_IN_BALANCES.includes(row.currency)"
                plain
                @click="
                  autoTrading
                  ? $router.push({name: 'TradingMode', params: {currency: row.currency, type: 'buy'}})
                  : $router.push({
                    name: 'RequestCreate',
                    params: {
                      requestType: GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER].ROUTE_LABEL,
                      currency: row.currency,
                      type: 'buy',
                    },
                  })
                "
              >
                Buy
              </el-button>
              <el-button
                class="ex-balance__action"
                type="success"
                :disabled="CURRENCIES_DISCONNECTED_IN_BALANCES.includes(row.currency)"
                plain
                @click="
                  autoTrading
                  ? $router.push({name: 'TradingMode', params: {currency: row.currency, type: 'sell'}})
                  : $router.push({
                    name: 'RequestCreate',
                    params: {
                      requestType: GENERAL_DESCRIPTION_TRADING_MODE[EXCHANGE_PRICE_BEHAVIOUR_TYPES.ORDER].ROUTE_LABEL,
                      currency: row.currency,
                      type: 'buy',
                    },
                  })
                "
              >
                Sell
              </el-button>
              <el-dropdown trigger="click" placement="bottom-start">
                  <span class="el-dropdown-link ex-balance__menu-link">
                    <i class="material-icons">more_horiz</i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    class="ex-balance__menu-item"
                    @click.native="$router.push({name: 'DepositCreate', params: {currency: row.currency}})"
                  >
                    Deposit
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="ex-balance__menu-item"
                    @click.native="$router.push({
                      name: 'WithdrawCrypto',
                      params: {currencyType: 'crypto', currency: row.currency, type: 'manual'}
                    })"
                  >
                    Withdraw
                  </el-dropdown-item>
                  <el-dropdown-item
                    class="ex-balance__menu-item"
                    @click.native="$router.push({name: 'FundingHistory', params: {currency: row.currency}})"
                  >
                    Funding history
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-if="row.type === CURRENCY_TYPE.FIAT" class="ex-balance__actions">
              <el-button
                class="ex-balance__action"
                type="warning"
                plain
                @click="$router.push({name: 'DepositCreate', params: {currency: row.currency}})"
              >
                Deposit
              </el-button>
              <el-button
                class="ex-balance__action"
                type="success"
                plain
                @click="$router.push({name: 'WithdrawFiat', params: {currency: row.currency}})"
              >
<!--              TODO Temporarily hiding fiat auto withdrawal creation form-->
<!--              @click="$router.push({name: 'WithdrawalFiatChoose', params: {currency: row.currency}})"-->
                Withdraw
              </el-button>
              <el-dropdown trigger="click" placement="bottom-start">
                  <span class="el-dropdown-link ex-balance__menu-link">
                    <i class="material-icons">more_horiz</i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    class="ex-balance__menu-item"
                    @click.native="$router.push({name: 'FundingHistory', params: {currency: row.currency}})"
                  >
                    Funding history
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <ex-confirm-gdpr />
    </div>
  </div>
</template>

<script>
import ExReport from '@/components/ex-report';
import ExConfirmGdpr from '@/components/ex-confirm-gdpr';

// api
import TransactionApi from '@/api/transactionService/transaction.api';

// utils
import {filterCoins} from '@/utils/filters';
import {formatCurrency, isTONLike} from '@/utils/converters/currency';
import {getCurrency} from '@/utils/exchange';
import {socketRequest} from '@/utils/socket';

// app
import {mapState} from 'vuex';

// const
import {GENERAL_DESCRIPTION_TRADING_MODE, EXCHANGE_PRICE_BEHAVIOUR_TYPES} from '@/constants/common';
import {FETCH_BALANCE_HISTORY} from '@/constants/events/transaction/actions.type';
import {
  CURRENCIES_DISCONNECTED_IN_BALANCES,
  CURRENCY_TYPE,
  SPECIAL_CURRENCIES_LIST,
} from '@/constants/currencies';
export default {
  name: 'ExBalance',
  components: {ExConfirmGdpr, ExReport},
  data() {
    return {
      isLoading: false,
      historyBalances: null,
      currencyType: 'all',
      date: '',
      optionsCurrencies: [
        {value: 'all', label: 'All currencies'},
        {value: 'crypto', label: 'Crypto currencies'},
        {value: 'fiat', label: 'Fiat currencies'},
      ],
      SPECIAL_CURRENCIES_LIST,
      CURRENCY_TYPE,
      CURRENCIES_DISCONNECTED_IN_BALANCES,
      GENERAL_DESCRIPTION_TRADING_MODE,
      EXCHANGE_PRICE_BEHAVIOUR_TYPES,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    ...mapState('content', {autoTrading: (state) => state.content.availableSections.autoTrading}),
    currentProfileBalance() {
      return filterCoins(this.profile.balance);
    },
    balancesFilteredByCurrencyType() {
      const balances = this.historyBalances !== null ? this.historyBalances : this.currentProfileBalance;
      switch (this.currencyType) {
        case CURRENCY_TYPE.FIAT:
        case CURRENCY_TYPE.CRYPTO: return balances.filter((item) => item.type === this.currencyType);
        default: return balances;
      }
    },
  },
  methods: {
    isTONLike,
    getCurrency,
    formatCurrency,
    applyTableFilter() {
      if (this.date === null) {
        this.date = ''; // TODO investigate ETH balance change
        return this.historyBalances = null;
      }
      socketRequest.call(this, FETCH_BALANCE_HISTORY,
        (payload) => {
          this.historyBalances = filterCoins(payload.balance);
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      TransactionApi.fetchBalanceHistory(FETCH_BALANCE_HISTORY, {balanceId: this.profile.balanceId, date: this.date});
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-balance {
  &__header {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px solid #e5e5ea;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 15px;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7px;
  }
  &__date {
    padding: 15px 10px 0;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    ::v-deep {
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        font-size: 16px;
      }
    }
  }
  &__date-button {
    margin-left: 10px;
  }
  &__menu-link {
    cursor: pointer;
    i {
      display: inline-block;
      margin-top: 5px;
      color: #ff9500;
      transition: 0.3s;
    }
    &:hover {
      i {
        color: lighten(#ff9500, 10%);
        transition: 0.3s;
      }
    }
  }
  &__menu-item {
    font-size: 16px;
    line-height: 1.5;
    padding: 8px 22px;
    transition: background 0.3s;
  }
  &__actions {
    display: flex;
    align-items: center;
  }
  &__action {
    min-width: 95px;
    margin-right: 15px;
    background-color: transparent;
  }
  &__first-column-label {
    margin-left: 15px;
  }
  &__third-column-label {
    margin-left: 84px;
  }
  &__icon {
    margin: 0 15px;
    max-width: 28px;
    max-height: 30px;
  }
  &__icon_blur {
    filter: blur(3px);
  }
  ::v-deep {
    .el-table {
      border: 1px solid #EBEEF5;
    }
    .el-table .cell {
      overflow: initial;
      font-size: 16px;
    }
  }
}
</style>
