import BaseApi from '@/api/base';

class MerchantApi extends BaseApi {
  /**
   * @param {string} event
   * @param {{}} data
   * @return {Promise<*>}
   */
  fetchMerchantInvoicesList(event, data) {
    return super.post(event, '/api/merchant/fetch-invoice-list', {data});
  }

  /**
   * @param {string} event
   * @param {{}} data
   * @return {Promise<*>}
   */
  fetchMerchantInvoice(event, data) {
    return super.post(event, '/api/merchant/fetch-invoice', {data});
  }

  /**
   * @param {string} event
   * @param {{}} data
   * @return {Promise<*>}
   */
  fetchMerchantInvoiceReport(event, data) {
    return super.post(event, '/api/merchant/fetch-invoice-report', {data});
  }

  /**
   * @param {string} event
   * @param {{}} data
   * @return {Promise<*>}
   */
  createMerchantInvoiceURL(event, data) {
    return super.post(event, '/api/merchant/create-invoice', {data});
  }
}

export default new MerchantApi();
