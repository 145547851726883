<template>
  <div class="ex-otc-trading-container">
    <ex-otc-trading-view-edit-layout>
      <template #name>Request - #{{request.id}}</template>
      <template #action>
        <ex-report
          v-show="request.id && (request.transactionStatus === 3 || request.transactionStatus === 7)"
          :value="{id: request.id}"
          :type="NAMES_TRANSACTION.EXCHANGE"
        >
          Get PDF Report
        </ex-report>
      </template>
      <ex-otc-trading-view :request="request" :last-transaction="lastTransaction" :history="history" />
      <ex-otc-trading-history
        v-if="request"
        v-loading="isFetchingHistory"
        :request="request"
        :history="preparedHistory"
      />
    </ex-otc-trading-view-edit-layout>
  </div>
</template>

<script>
// components
import ExOtcTradingViewEditLayout from '@/pages/requests/otc-trading/ex-otc-trading-view-edit-layout';
import ExOtcTradingHistory from '@/pages/requests/otc-trading/ex-otc-trading-history';
import ExOtcTradingView from '@/pages/requests/otc-trading/ex-otc-trading-view';
import ExReport from '@/components/ex-report';

// constant
import {NAMES_TRANSACTION} from '@/constants/common';

export default {
  name: 'ExOtcTradingContainer',
  components: {
    ExOtcTradingViewEditLayout,
    ExOtcTradingHistory,
    ExOtcTradingView,
    ExReport,
  },
  props: {
    request: {
      type: Object,
      required: true,
      default: () => {},
    },
    isFetchingHistory: {
      type: Boolean,
      default: false,
    },
    lastTransaction: {
      type: Object,
      required: true,
      default: () => {},
    },
    history: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      NAMES_TRANSACTION,
    };
  },
  computed: {
    preparedHistory() {
      return this.history.slice().reverse();
    },
  },
};
</script>
