import BaseServiceApi from '@/api/base';

export default new class ProfileApi extends BaseServiceApi {
  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchUserProfile(event) {
    return super.post(event, '/api/user/fetch-profile');
  }

  /**
   * @param {string} event
   * @param {string} data
   * @return {Promise<*>}
   */
  fetchUserRoles(event, data) {
    return super.post(event, '/api/user/fetch-roles', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  fetchNotificationSettings(event) {
    return super.post(event, '/api/user/notification/fetch-settings');
  }
  /**
   * @param {string} event
   * @param {{}} data
   * @return {Promise<*>}
   */
  updateNotificationSetting(event, data) {
    return super.post(event, '/api/user/notification/update-setting', {data});
  }

  /**
   * @param {string} event
   * @param {{
   *   userId: string,
   *   policyVersion: string,
   * }} data
   * @return {Promise<*>}
   */
  updateGdprPolicyVersion(event, data) {
    return super.post(event, '/api/user/profile/update-gdpr-policy-version', {data});
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  async requestUserPersonalData(event) {
    return await super.post(event, '/api/user/profile/request-user-personal-data');
  }

  /**
   * @param {string} event
   * @return {Promise<*>}
   */
  async revokeUserPersonalData(event) {
    return await super.post(event, '/api/user/profile/revoke-user-personal-data');
  }
};
