<template>
  <div class="merchant-invoices">
    <portal to="toolbar__title">Envoice</portal>
    <div class="merchant-invoices__create">
      <router-link :to="{name: 'MerchantInvoiceCreate'}">
        <el-button icon="el-icon-circle-plus">Create Invoice URL</el-button>
      </router-link>
    </div>
    <div class="merchant-invoices__table">
      <el-table
        ref="invoicesTable"
        stripe
        :data.sync="findingItem ? [] : merchantInvoice.invoices.list"
        highlight-current-row
        @row-click="selectRow"
      >
        <el-table-column label="Date / Timestamp" align="center" width="95">
          <template slot-scope="scope">
            <span>{{utilTimeFormatOrMessage(scope.row.updateAt)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Merchant’s Invoice Number / Client Number"
          align="center"
          min-width="110"
          prop="clientInvoice"
        />
        <el-table-column :label="`${brand} Transaction Number`" align="center" min-width="150">
          <template slot-scope="scope">
            <span :ref="'element' + scope.row.id">{{scope.row.id}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Payment Currency" align="center">
          <template slot-scope="scope">
            <span>{{getPairCurrencies(scope.row.currencyPair).first}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Amount in Payment Currency" align="center" prop="amountCurrencyFirst" />
        <el-table-column label="Base Currency" align="center">
          <template slot-scope="scope">
            <span>{{getPairCurrencies(scope.row.currencyPair).second}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Amount in Base Currency" align="center" prop="amountCurrencyLast" />
        <el-table-column label="Status" align="center">
          <template slot-scope="scope">
            <span>{{nameStatus(scope.row.status)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="Details" width="100" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="showItem(scope.row.id)">Details</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="merchantInvoice.invoices.pagination.totalPages > 1" class="merchant-invoices__pagination">
      <ex-pagination
        :total="merchantInvoice.invoices.pagination.totalItems"
        :currentPage="+merchantInvoice.invoices.pagination.currentPage"
        :pageSize="+merchantInvoice.invoices.pagination.limitItems"
        @fetch="fetch"
      />
    </div>
  </div>
</template>

<script>
// Components
import ExPagination from '@/components/ex-pagination';

// Lib
import {mapState, mapGetters} from 'vuex';

// Mixin
import ItemSearch from '@/mixins/itemSearchMixin';

// Utils
import {utilTimeFormatOrMessage} from '@/utils/converters/time';
import {getPairCurrencies} from '@/utils/converters/currency';

export default {
  name: 'merchantInvoices',

  components: {ExPagination},

  mixins: [ItemSearch],

  data() {
    return {
      itemId: this.$route.params.id,
      limit: 20,
    };
  },

  created() {
    this.$_itemSearchMixin_initiate(this.setFindingStatus);
  },

  computed: {
    ...mapState({
      merchantInvoice: (state) => state.merchantInvoice.data,
    }),
    ...mapState('content', {brand: (state) => state.content.brand}),
    ...mapGetters({
      nameStatus: 'merchantInvoice/nameStatus',
    }),

    findingItem() {
      return this.$store.state.merchantInvoice.meta.findingInvoice;
    },

    itemsList() {
      return this.merchantInvoice.invoices.list;
    },

    page() {
      return this.merchantInvoice.invoices.pagination.currentPage;
    },

    refTable() {
      return this.$refs.invoicesTable;
    },
    refItem() {
      return this.$refs[`element${this.itemId}`];
    },
  },

  methods: {
    utilTimeFormatOrMessage,
    getPairCurrencies,

    fetch(page, limit = 20) {
      this.$store.dispatch('merchantInvoice/fetchList', {page, limit});
    },

    setFindingStatus(status) {
      this.$store.commit('merchantInvoice/setFindingInvoice', status);
    },

    selectRow(row) {
      this.showItem(row.id);
    },

    showItem(id) {
      this.$router.push({
        name: 'MerchantInvoiceView',
        params: {id},
        query: {
          page: this.merchantInvoice.invoices.pagination.currentPage,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant-invoices {
  &__create {
    display: inline-block;
    float: right;
    margin-bottom: 15px;
  }
  &__title {
    font-weight: 600;
    line-height: 1.2;
    font-size: 20px;
  }
  .el-table {
    ::v-deep .cell {
      word-break: break-word !important;
    }
    ::v-deep .el-table__row {
      cursor: pointer;
    }
  }
  &__pagination {
    margin: 8px 0 0 0;
  }
}
</style>
