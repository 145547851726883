<template>
  <div class="ex-ttr">
    <div class="ex-ttr__header" @click="isOpen = !isOpen">
      <div class="ex-ttr__icon">
        <i :class="icon" />
      </div>
      <div class="ex-ttr__info">
        <div class="ex-ttr__info-value">
          {{info}}
        </div>
        <div class="ex-ttr__info-date">
          {{utilTimeFormatOrMessage(value.date)}}
        </div>
      </div>
      <div class="ex-ttr__money">
        <div class="ex-ttr__money-value" :class="{'ex-ttr__money-value_success': operationBool}">
          <div class="ex-ttr__money-amount">{{operation}} {{amount}}</div>
          <div class="ex-ttr__money-currency">{{currency}}</div>
        </div>
        <div class="ex-ttr__money-balance">
          <span v-if="isExchange">
            {{operationReverse}} {{secondAmount}} {{secondCurrency}}
          </span>
          <span v-else>
            = {{balance}}
          </span>
        </div>
      </div>
      <div class="ex-ttr__chevron" :class="{'ex-ttr__chevron_open': isOpen}">
        <i class="el-icon-arrow-down" />
      </div>
    </div>
    <div class="ex-ttr__content">
      <ex-transaction-table-content :data="contentData" :is-open="isOpen">
        <template v-slot:actions>
          <slot name="actions" />
        </template>
      </ex-transaction-table-content>
    </div>
  </div>
</template>

<script>
// constants
import {
  REQUEST_TRANSACTION_TYPES,
  PAYMENT_METHODS,
  FIAT_CURRENCIES_LIST,
  CURRENCY_TYPE,
  EXCHANGE_STATUS,
  DEPOSIT_FEE_STATUS,
  DEPOSIT_STATUS,
  WITHDRAW_STATUS,
  WITHDRAW_AUTO_STATUS,
  WITHDRAW_FEE_STATUS,
  NETWORK_FEE_STATUS,
  COMMISSION_CATEGORY,
  COMMISSION_CATEGORY_LABEL,
  COMMISSION_STATUS,
  CRYPTO_CURRENCIES_LIST,
  NAMES_TRANSACTION,
} from '@/constants/common';

// component
import ExTransactionTableContent from '@/pages/transactions/ex-transaction-table-content';

// utils
import {getPairCurrencies, formatCurrency} from '@/utils/converters/currency';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';

export default {
  name: 'ExTransactionTableRow',
  components: {ExTransactionTableContent},
  props: {
    value: {
      type: Object,
      default: () => ({
        id: null,
        price: null,
        transactionDate: {date: '', timezone: ''},
        type: null,
        status: null,
        amount: null,
        currency: '',
        currencyPair: '',
        balance: null,
        entityName: '',
        reverse: false,
        fee: null,
        uidAddress: '',
      }),
    },
  },
  data() {
    return {
      isOpen: false,
      TYPES: REQUEST_TRANSACTION_TYPES,
      PAYMENT_METHODS,
      NAMES_TRANSACTION,
    };
  },
  computed: {
    isExchange() {
      return this.value.entityName === NAMES_TRANSACTION.EXCHANGE;
    },
    currencyType() {
      return this.getCurrencyType(this.currency);
    },
    secondCurrencyType() {
      return this.getCurrencyType(this.secondCurrency);
    },
    amount() {
      const amount = this.isExchange ? this.value.amount[this.firstCurrency] : this.value.amount;
      return formatCurrency(amount, this.currencyType);
    },
    balance() {
      const balance = this.isExchange ? this.value.balance[this.firstCurrency] : this.value.balance;
      return formatCurrency(balance, this.currencyType);
    },
    secondBalance() {
      return formatCurrency(this.value.balance[this.secondCurrency], this.secondCurrencyType);
    },
    firstCurrency() {
      return getPairCurrencies(this.value.currencyPair).first;
    },
    secondCurrency() {
      return getPairCurrencies(this.value.currencyPair).second;
    },
    currency() {
      return this.isExchange ? this.firstCurrency : this.value.currency;
    },
    secondAmount() {
      return formatCurrency(this.value.amount[this.secondCurrency], this.getCurrencyType(this.secondCurrency));
    },
    price() {
      if (this.isExchange) {
        const firstPrice = this.value.price[this.firstCurrency];
        const secondPrice = this.value.price[this.secondCurrency];

        return {
          [this.firstCurrency]: firstPrice.toString(),
          [this.secondCurrency]: formatCurrency(secondPrice, this.getCurrencyType(this.secondCurrency)),
        };
      }

      return formatCurrency(this.value.price, this.getCurrencyType(this.secondCurrency));
    },
    icon() {
      if (this.isReversed) {
        return 'el-icon-refresh-right';
      }

      switch (this.value.entityName) {
        case NAMES_TRANSACTION.EXCHANGE: return 'el-icon-sort';
        case NAMES_TRANSACTION.IT_DEPOSIT:
        case NAMES_TRANSACTION.DEPOSIT: return 'el-icon-bottom';
        case NAMES_TRANSACTION.DEPOSIT_FEE:
        case NAMES_TRANSACTION.WITHDRAWAL_NETWORK_FEE:
        case NAMES_TRANSACTION.DEPOSIT_NETWORK_FEE:
        case NAMES_TRANSACTION.WITHDRAWAL_FEE:
        case NAMES_TRANSACTION.WITHDRAWAL_AUTO:
        case NAMES_TRANSACTION.MANUAL_COMMISSION:
        case NAMES_TRANSACTION.IT_WITHDRAWAL:
        case NAMES_TRANSACTION.IT_WITHDRAWAL_FEE:
        case NAMES_TRANSACTION.IT_DEPOSIT_FEE:
        case NAMES_TRANSACTION.WITHDRAWAL: return 'el-icon-top';
        default: return '';
      }
    },
    operationBool() { // Add only for operations in plus or with special conditions
      const operations = {
        [NAMES_TRANSACTION.EXCHANGE]: (this.currency === this.secondCurrency && this.value.type === this.TYPES.sell)
          || (this.currency === this.firstCurrency && this.value.type === this.TYPES.buy),
        [NAMES_TRANSACTION.DEPOSIT]: true,
        [NAMES_TRANSACTION.MANUAL_COMMISSION]: this.value.category === COMMISSION_CATEGORY.REWARD,
        [NAMES_TRANSACTION.IT_DEPOSIT]: true,
      };

      const operation = operations[this.value.entityName];

      return this.isReversed ? !operation : operation;
    },
    operation() {
      return this.operationBool ? '+' : '-';
    },
    operationReverse() {
      return this.operationBool ? '-' : '+';
    },
    info() {
      const reverse = this.isReversed ? 'REVERSE ' : '';

      switch (this.value.entityName) {
        case NAMES_TRANSACTION.EXCHANGE:
          return `${reverse}${(this.TYPES[this.value.type] || '').toUpperCase()} ${this.price[this.firstCurrency]} ${this.firstCurrency} =
            ${this.secondCurrency} ${this.price[this.secondCurrency]}`;
        case NAMES_TRANSACTION.DEPOSIT:
          return `${reverse}DEPOSIT ${this.PAYMENT_METHODS[this.value.paymentMethod]} ${this.value.uidAddress || ''}`;
        case NAMES_TRANSACTION.DEPOSIT_FEE: return `${reverse}DEPOSIT FEE ${this.value.fee}%`;
        case NAMES_TRANSACTION.WITHDRAWAL_NETWORK_FEE: return `${reverse}NETWORK FEE WITHDRAWAL`;
        case NAMES_TRANSACTION.DEPOSIT_NETWORK_FEE: return `${reverse}NETWORK FEE DEPOSIT`;
        case NAMES_TRANSACTION.WITHDRAWAL: return `${reverse}WITHDRAWAL ${this.PAYMENT_METHODS[this.value.paymentMethod]}`;
        case NAMES_TRANSACTION.WITHDRAWAL_AUTO: return `${reverse}WITHDRAWAL AUTO`;
        case NAMES_TRANSACTION.WITHDRAWAL_FEE: return `${reverse}WITHDRAWAL FEE ${this.value.fee}%`;
        case NAMES_TRANSACTION.MANUAL_COMMISSION:
          return `${reverse}MANUAL ${this.value.category === COMMISSION_CATEGORY.FEE ? 'FEE' : 'REWARD'} (${this.value.commissionType})`;
        case NAMES_TRANSACTION.IT_WITHDRAWAL: return 'INTERNAL WITHDRAWAL';
        case NAMES_TRANSACTION.IT_DEPOSIT: return 'INTERNAL DEPOSIT';
        case NAMES_TRANSACTION.IT_WITHDRAWAL_FEE: return `INTERNAL WITHDRAWAL FEE ${this.value.rateCommission}%`;
        case NAMES_TRANSACTION.IT_DEPOSIT_FEE: return `INTERNAL DEPOSIT FEE ${this.value.rateCommission}%`;
        default: return '';
      }
    },
    isReversed() { // todo remake to switch
      if (this.isExchange && this.value.status === EXCHANGE_STATUS.REVERSED) {
        return true;
      }
      if (
        this.value.entityName === NAMES_TRANSACTION.DEPOSIT_FEE
        && this.value.status === DEPOSIT_FEE_STATUS.REVERSED
      ) {
        return true;
      }
      if (
        [NAMES_TRANSACTION.WITHDRAWAL_NETWORK_FEE, NAMES_TRANSACTION.DEPOSIT_NETWORK_FEE]
          .includes(this.value.entityName) && this.value.status === NETWORK_FEE_STATUS.REVERSED
      ) {
        return true;
      }
      if (
        this.value.entityName === NAMES_TRANSACTION.WITHDRAWAL
        && this.value.status === WITHDRAW_STATUS.REVERSED
      ) {
        return true;
      }
      if (
        this.value.entityName === NAMES_TRANSACTION.WITHDRAWAL_AUTO
        && this.value.status === WITHDRAW_AUTO_STATUS.REVERSED
      ) {
        return true;
      }
      if (
        this.value.entityName === NAMES_TRANSACTION.WITHDRAWAL_FEE
        && this.value.status === WITHDRAW_FEE_STATUS.REVERSED
      ) {
        return true;
      }
      if (
        this.value.entityName === NAMES_TRANSACTION.MANUAL_COMMISSION
        && this.value.status === COMMISSION_STATUS.REVERSED
      ) {
        return true;
      }
      return this.value.entityName === NAMES_TRANSACTION.DEPOSIT && this.value.status === DEPOSIT_STATUS.REVERSED;

    },
    contentData() {
      const reverse = this.isReversed ? 'Reverse ' : '';
      let pay;
      let payBalance;
      let receive;
      let receiveBalance;

      if (this.isExchange) {
        if (this.operationBool) {
          pay = `- ${this.secondAmount} ${this.secondCurrency}`;
          payBalance = `= ${this.secondBalance} ${this.secondCurrency}`;
          receive = `+ ${this.amount} ${this.currency}`;
          receiveBalance = `= ${this.balance} ${this.currency}`;
        } else {
          pay = `- ${this.amount} ${this.currency}`;
          payBalance = `= ${this.balance} ${this.currency}`;
          receive = `+ ${this.secondAmount} ${this.secondCurrency}`;
          receiveBalance = `= ${this.secondBalance} ${this.secondCurrency}`;
        }
      }

      switch (this.value.entityName) {
        case NAMES_TRANSACTION.EXCHANGE:
          return [
            [
              {label: 'Type', value: `${reverse}Exchange (${this.TYPES[this.value.type]})`},
              {label: 'Transaction Id', value: this.value.id},
            ],
            [{label: 'You pay', value: pay}, {label: 'Balance', value: payBalance}],
            [{label: 'You receive', value: receive}, {label: 'Balance', value: receiveBalance}],
          ];

        case NAMES_TRANSACTION.DEPOSIT:
          return [
            [{label: 'Type', value: `${reverse}Deposit`}, {label: 'Transaction Id', value: this.value.id}, {}],
            [
              {label: 'Payment Method', value: this.PAYMENT_METHODS[this.value.paymentMethod]},
              {label: 'UID Address', value: this.value.uidAddress || '–'},
              {label: 'Hash ID', value: this.value.hash, href: this.createLink(this.currency, this.value.hash)},
            ],
            [
              {
                label: this.isReversed ? 'You pay': 'You receive',
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
              {},
            ],
          ];

        case NAMES_TRANSACTION.DEPOSIT_FEE:
          return [
            [{label: 'Type', value: `${reverse}Deposit Fee`}, {label: 'Transaction Id', value: this.value.id}],
            [{label: 'Rate commission', value: `${this.value.fee}%`}],
            [
              {
                label: this.isReversed ? 'You receive' : 'You pay',
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.WITHDRAWAL_NETWORK_FEE:
          return [
            [{label: 'Type', value: `${reverse}Network Fee Withdrawal`}, {label: 'Transaction Id', value: this.value.id}],
            [
              {
                label: this.isReversed ? 'You receive' : 'You pay',
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.DEPOSIT_NETWORK_FEE:
          return [
            [{label: 'Type', value: `${reverse}Network Fee Deposit`}, {label: 'Transaction Id', value: this.value.id}],
            [
              {
                label: this.isReversed ? 'You receive' : 'You pay',
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.WITHDRAWAL:
          return [
            [
              {label: 'Type', value: `${reverse}Withdraw`},
              {label: 'Transaction Id', value: this.value.id},
            ],
            [
              {label: 'Method', value: this.PAYMENT_METHODS[this.value.paymentMethod]},
              {label: 'Hash ID', value: this.value.hash, href: this.createLink(this.currency, this.value.hash)},
            ],
            [
              {label: `${reverse}Withdrawal`, value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.WITHDRAWAL_AUTO:
          return [
            [{label: 'Type', value: `${reverse}Withdraw Auto`}, {label: 'Transaction Id', value: this.value.id}],
            [
              {label: 'Fee', value: `${this.value.fee}%`},
              {label: 'Hash ID', value: this.value.hash, href: this.createLink(this.currency, this.value.hash)},
            ],
            [
              {label: `${reverse}Withdrawal`, value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.WITHDRAWAL_FEE:
          return [
            [{label: 'Type', value: `${reverse}Withdraw Fee`}, {label: 'Transaction Id', value: this.value.id}],
            [{label: 'Rate commission', value: `${this.value.fee}%`}],
            [
              {label: `${reverse}Withdrawal`, value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.MANUAL_COMMISSION:
          return [
            [
              {label: 'Category', value: `Manual ${COMMISSION_CATEGORY_LABEL[this.value.category]}`},
              {label: 'Transaction Id', value: this.value.id},
            ],
            [
              {label: 'Type', value: `${this.value.commissionType}`},
            ],
            [
              {
                label: this.isReversed
                  ? (COMMISSION_CATEGORY.FEE === this.value.category ? 'You receive': 'You pay')
                  : (COMMISSION_CATEGORY.FEE === this.value.category ? 'You pay': 'You receive'),
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.IT_WITHDRAWAL:
          return [
            [{label: 'Type', value: 'Internal Withdrawal'}, {label: 'Transaction Id', value: this.value.id}],
            [{label: 'Hash', value: `${this.value.hash}`}],
            [
              {label: 'Internal Withdrawal', value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.IT_WITHDRAWAL_FEE:
          return [
            [{label: 'Type', value: 'Internal Withdrawal Fee'}, {label: 'Transaction Id', value: this.value.id}],
            [
              {label: 'Internal Withdrawal', value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.IT_DEPOSIT_FEE:
          return [
            [{label: 'Type', value: 'Internal Deposit FEE'}, {label: 'Transaction Id', value: this.value.id}],
            [
              {label: 'Internal Deposit FEE', value: `${this.operation} ${this.amount} ${this.currency}`},
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        case NAMES_TRANSACTION.IT_DEPOSIT:
          return [
            [{label: 'Type', value: 'Internal Deposit'}, {label: 'Transaction Id', value: this.value.id}],
            [{label: 'Hash', value: `${this.value.hash}`}],
            [
              {
                label: 'You receive',
                value: `${this.operation} ${this.amount} ${this.currency}`,
              },
              {label: 'Balance', value: `= ${this.balance} ${this.currency}`},
            ],
          ];

        default: return [];
      }
    },
  },

  methods: {
    utilTimeFormatOrMessage,
    getCurrencyType(currency) {
      return FIAT_CURRENCIES_LIST[currency] ? CURRENCY_TYPE.FIAT : CURRENCY_TYPE.CRYPTO;
    },
    createLink(currency, hash) {
      switch (currency) {
        case CRYPTO_CURRENCIES_LIST.BTC:
          return `https://btc1.trezor.io/tx/${hash}`;
        case CRYPTO_CURRENCIES_LIST.ETH:
        case CRYPTO_CURRENCIES_LIST.USDT_ERC20:
        case CRYPTO_CURRENCIES_LIST.BUSD:
        case CRYPTO_CURRENCIES_LIST.DAI:
        case CRYPTO_CURRENCIES_LIST.USDC:
          return `https://etherscan.io/tx/${hash}`;
        case CRYPTO_CURRENCIES_LIST.USDT_Polygon:
        case CRYPTO_CURRENCIES_LIST.MATIC_Polygon:
          return `https://polygonscan.com/tx/${hash}`;
        case CRYPTO_CURRENCIES_LIST.USDT_TRC20:
        case CRYPTO_CURRENCIES_LIST.TRX:
          return `https://tronscan.org/#/transaction/${hash}`;
        case CRYPTO_CURRENCIES_LIST.BNB:
        case CRYPTO_CURRENCIES_LIST.USDT_BEP20:
        case CRYPTO_CURRENCIES_LIST.ETH_BEP20:
        case CRYPTO_CURRENCIES_LIST.USDC_BEP20:
          return `https://bscscan.com/tx/${hash}`;
        default: return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-ttr {
  position: relative;
  &:hover {
    .ex-ttr__icon {
      background: #EBEEF5;
    }
    ::v-deep .ex-ttc__left {
      border-color: #EBEEF5;
    }
  }
  &__header {
    display: flex;
    padding: 22px 32px;
    cursor: pointer;
  }
  &__icon {
    border-radius: 4px;
    height: 50px;
    width: 50px;
    background: #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-size: 24px;
      color: #606266;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 32px;
    flex: 1 1 50%;
    overflow: hidden;
    &-value {
      color: #383838;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-date {
      color: #8F8F8F;
    }
  }
  &__money {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 32px;
    &-value {
      font-size: 18px;
      color: #383838;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      &_success {
        color: #589F25;
      }
    }
    &-currency {
      padding-left: 8px;
      font-size: 14px;
      color: #383838;
    }
    &-balance {
      color: #8F8F8F;
    }
  }
  &__chevron {
    font-size: 24px;
    display: flex;
    align-items: center;
    i {
      transition: transform .2s linear;
    }
    &_open {
      i {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
