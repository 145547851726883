// util
import {filterCoinsAndFormType} from '@/utils/filters';
import {hasPayloadError} from '@/utils/error';
import vueInstance from '@/utils/vueInstance';
import eventBus from '@/utils/eventBus';
import {cloneDeep} from 'lodash';
import {resetState} from '@/utils/storeUtils';

// api
import ProfileApi from '@/api/userService/profile';
import TunnelApi from '@/api/tunnel.api';
import TradingApi from '@/api/trading.api';
import InternalTransferApi from '@/api/internalTransfer.api';

// const
import {getWSA} from '@/constants/events/actions.type';
import {NOTIFICATION_WARNING_MESSAGE_NO_ENOUGH_DATA} from '@/constants/commonMessage';
import {TEMPORARILY_UNAVAILABLE_COINS} from '@/constants/currencies';
import {FETCH_COUNTER_PENDING_ORDERS} from '@/constants/events/store/exchange/actions.type';
import {CUSTOMER_STATUSES} from '@/constants/common';
import {CURRENCY_TYPE} from '@/constants/currencies';
import {UPDATE_USER_SMS_SETTING} from '@/constants/events/currentUser/actions.type';
import {
  FETCH_BILLING_PRESET,
  FETCH_TRADING_PRESET,
  FETCH_USER_PROFILE,
  FETCH_USER_ROLES,
  FETCH_PRESET_INTERNAL_TRANSFER,
} from '@/constants/events/store/currentUser/actions.type';
import {
  ROLE_GENERAL,
  ROLE_BILLING,
  ROLE_TRADING,
  ROLE_MERCHANT,
  ROLE_INTERNAL_TRANSFER,
} from '@/constants/roles';
import {
  SUBSCRIBE_BALANCES,
  SUBSCRIBE_INDIVIDUAL_PROFILE,
  SUBSCRIBE_ORGANIZATION_PROFILE,
  SUBSCRIBE_USER,
} from '@/constants/events/subscriptions.type';

const delay = 10 * 1000;

const initState = {
  id: null,
  profile: {
    activeStatus: null,
  },
  roles: [],
  preset: {
    billing: {
      apiKey: '',
      depositFee: 0,
      margin: 0,
      networkFeeWithdraw: true,
      outgoingCommission: 0,
      withdrawFee: 0,
    },
    trading: {
      margin: 0,
      marginFiat: 0,
    },
    internalTransfer: {
      recipientId: 'N/A',
      withdrawFee: 0,
      depositFee: 0,
    },
  },
  meta: {
    isFetching: {
      profile: false,
      roles: false,
      billingPreset: false,
      tradingPreset: false,
      internalTransferPreset: false,
    },
    isReceived: {
      profile: false,
      roles: false,
      billingPreset: false,
      tradingPreset: false,
      internalTransferPreset: false,
    },
    timeoutId: {
      profile: null,
      roles: null,
      billingPreset: null,
      tradingPreset: null,
      internalTransferPreset: null,
    },
  },
};

const state = cloneDeep(initState);
const getters = {
  currentId(state) {
    return state.profile.organizationId || state.profile.id || null;
  },
  cryptoBalances(state) {
    return filterCoinsAndFormType(state.profile.balance, CURRENCY_TYPE.CRYPTO);
  },
  fiatBalances(state) {
    return filterCoinsAndFormType(state.profile.balance, CURRENCY_TYPE.FIAT);
  },
  clientName(state, getters, rootGetters) { // todo use
    if (rootGetters['auth/isCompany']) {
      return state.profile?.organizationName;
    } else {
      let clientName = '';
      if (state.profile?.username) {
        clientName += ` ${state.profile?.username}`;
      }
      if (state.profile?.lastName) {
        clientName += ` ${state.profile?.lastName}`;
      }
      clientName = clientName.slice(1);
      return clientName;
    }
  },
  userIsReady(state) {
    return state.meta.isReceived.profile && state.meta.isReceived.roles;
  },
  hasGeneralRole(state) {
    return state.roles.includes(ROLE_GENERAL);
  },
  hasBillingRole(state) {
    return state.roles.includes(ROLE_BILLING);
  },
  hasTradingRole(state) {
    return state.roles.includes(ROLE_TRADING);
  },
  hasMerchantRole(state) {
    return state.roles.includes(ROLE_MERCHANT);
  },
  hasInternalTransferRole(state) {
    return state.roles.includes(ROLE_INTERNAL_TRANSFER);
  },
  hasBillingData(state) {
    return !state.roles.includes(ROLE_BILLING)
      || state.roles.includes(ROLE_BILLING) && state.meta.isReceived.billingPreset;
  },
  hasTradingData(state) {
    return !state.roles.includes(ROLE_TRADING)
      || state.roles.includes(ROLE_TRADING) && state.meta.isReceived.tradingPreset;
  },
  hasInternalTransferData(state) {
    return !state.roles.includes(ROLE_INTERNAL_TRANSFER)
      || state.roles.includes(ROLE_INTERNAL_TRANSFER) && state.meta.isReceived.internalTransferPreset;
  },
  isFreezeAcc(state) {
    return CUSTOMER_STATUSES.FREEZE === state.profile.activeStatus;
  },
};

const mutations = {
  updateBalance(state, data) {
    state.profile.balance = data.filter((item) => !TEMPORARILY_UNAVAILABLE_COINS.includes(item.currency));
  },
  request(state, type) {
    // todo Convert to centralized use
    if (!Object.hasOwnProperty.call(state.meta.isFetching, type)) return;
    state.meta.isFetching[type] = true;
  },
  response(state, type) {
    // todo Convert to centralized use
    if (!Object.hasOwnProperty.call(state.meta.isFetching, type)) return;
    state.meta.isFetching[type] = false;
  },
  handleReceiving(state, type) {
    if (!Object.hasOwnProperty.call(state.meta.isReceived, type)) return;
    state.meta.isReceived[type] = true;
    clearTimeout(state.meta.timeoutId[type]);
  },
  setPreset(state, {field, value}) {
    if (!Object.hasOwnProperty.call(state.preset, field)) return;
    if (field === 'billing') {
      state.preset[field] = Object.assign({}, state.preset[field], value.settings, {apiKey: value.apiKey});
    } else {
      state.preset[field] = Object.assign({}, state.preset[field], value);
    }
  },
  reset(state) {
    resetState(initState, state);
    clearTimeout(state.meta.timeoutId.profile);
    clearTimeout(state.meta.timeoutId.roles);
    clearTimeout(state.meta.timeoutId.billingPreset);
    clearTimeout(state.meta.timeoutId.tradingPreset);
    clearTimeout(state.meta.timeoutId.internalTransferPreset);
  },
  setId(state, {userId}) {
    state.id = userId;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  setRoles(state, roles) {
    state.roles = roles;
  },
  updateOrganizationProfile(state, payload) {
    state.profile.agentId = payload.agentId;
    state.profile.clientId = payload.clientId;
    state.profile.organizationName = payload.name;
    state.profile.organizationRegNumber = payload.registrationNumber;
    state.profile.verification = payload.verification;
  },
  updateIndividualProfileForIndividual(state, payload) {
    state.profile.agentId = payload.agentId;
    state.profile.clientId = payload.clientId;
    state.profile.email = payload.email;
    state.profile.phone = payload.phone;
    state.profile.flagGA = payload.flagGA;
    state.profile.flagSMS = payload.flagSMS;
    state.profile.lastName = payload.lastName;
    state.profile.username = payload.username;
    state.profile.verification = payload.verification;
  },
  updateIndividualProfileForOrganization(state, payload) {
    state.profile.agentId = payload.agentId;
    state.profile.email = payload.email;
    state.profile.phone = payload.phone;
    state.profile.flagGA = payload.flagGA;
    state.profile.flagSMS = payload.flagSMS;
    state.profile.lastName = payload.lastName;
    state.profile.username = payload.username;
  },
  setGDPRVersion(state, version) {
    state.profile.policyVersion = version;
  },
};

const actions = {
  fetchPrimaryUserData({dispatch}) {
    dispatch(FETCH_USER_ROLES);
    dispatch(FETCH_USER_PROFILE);
  },
  [FETCH_USER_PROFILE]({commit, dispatch}) {
    commit('request', 'profile');
    dispatch('setWaitingResponse', 'profile');
    return ProfileApi.fetchUserProfile(FETCH_USER_PROFILE);
  },
  [getWSA(FETCH_USER_PROFILE)]({commit, dispatch}, payload) {
    commit('response', 'profile');
    if (hasPayloadError(payload)) return;
    commit('setProfile', payload);
    commit('updateBalance', payload.balance);
    commit('handleReceiving', 'profile');
    dispatch('appState/initBasicSubscribe', null, {root: true});
  },
  [FETCH_USER_ROLES]({commit, rootState, dispatch}) {
    commit('request', 'roles');
    dispatch('setWaitingResponse', 'roles');
    return ProfileApi.fetchUserRoles(FETCH_USER_ROLES, {accessId: rootState.auth.accessId});
  },
  [getWSA(FETCH_USER_ROLES)]({commit, dispatch}, payload) {
    commit('response', 'roles');
    if (hasPayloadError(payload)) return vueInstance.errorMsg('Unrecognized error. Please update the page');
    dispatch('fetchSecondaryUserData', payload.data);
    commit('setRoles', payload.data);
    commit('handleReceiving', 'roles');
  },
  fetchSecondaryUserData({dispatch}, roles) {
    if (roles.includes(ROLE_GENERAL)) {
      dispatch('content/fetchGDPR', {}, {root: true});
      dispatch(`exchange/${FETCH_COUNTER_PENDING_ORDERS}`, {}, {root: true});
    }
    if (roles.includes(ROLE_BILLING)) {
      dispatch(FETCH_BILLING_PRESET);
    }
    if (roles.includes(ROLE_TRADING)) {
      dispatch(FETCH_TRADING_PRESET);
    }
    if (roles.includes(ROLE_INTERNAL_TRANSFER)) {
      dispatch(FETCH_PRESET_INTERNAL_TRANSFER);
    }
  },
  [getWSA(SUBSCRIBE_INDIVIDUAL_PROFILE)]({commit, rootGetters}, payload) {
    if (rootGetters['auth/isCompany']) {
      commit('updateIndividualProfileForOrganization', payload);
    } else {
      commit('updateIndividualProfileForIndividual', payload);
    }
  },
  [getWSA(SUBSCRIBE_ORGANIZATION_PROFILE)]({commit}, payload) {
    commit('updateOrganizationProfile', payload);
  },
  [getWSA(SUBSCRIBE_BALANCES)]({commit}, payload) {
    commit('updateBalance', payload);
  },
  [getWSA(SUBSCRIBE_USER)]({state}, payload) {
    if (payload.clientStatus === CUSTOMER_STATUSES.CLOSED) eventBus.$emit('logout');
  },
  [getWSA(UPDATE_USER_SMS_SETTING)]({commit}, payload) {},
  [FETCH_TRADING_PRESET]({commit, dispatch}) {
    commit('request', 'tradingPreset');
    dispatch('setWaitingResponse', 'tradingPreset');
    return TradingApi.fetchPreset(FETCH_TRADING_PRESET);
  },
  [getWSA(FETCH_TRADING_PRESET)]({commit}, payload) {
    commit('response', 'tradingPreset');
    if (hasPayloadError(payload)) return;

    commit('setPreset', {field: 'trading', value: payload});
    commit('handleReceiving', 'tradingPreset');
  },

  [FETCH_BILLING_PRESET]({commit, dispatch}) {
    commit('request', 'billingPreset');
    dispatch('setWaitingResponse', 'billingPreset');
    return TunnelApi.fetchTunnelPreset(FETCH_BILLING_PRESET);
  },
  [getWSA(FETCH_BILLING_PRESET)]({commit}, data) {
    commit('response', 'billingPreset');
    if (hasPayloadError(data)) return;

    commit('setPreset', {field: 'billing', value: data});
    commit('handleReceiving', 'billingPreset');
  },

  [FETCH_PRESET_INTERNAL_TRANSFER]({commit, dispatch}) {
    commit('request', 'internalTransferPreset');
    dispatch('setWaitingResponse', 'internalTransferPreset');
    return InternalTransferApi.fetchPresetInternalTransfer(FETCH_PRESET_INTERNAL_TRANSFER);
  },
  [getWSA(FETCH_PRESET_INTERNAL_TRANSFER)]({commit}, data) {
    commit('response', 'internalTransferPreset');
    if (hasPayloadError(data)) return;
    commit('setPreset', {field: 'internalTransfer', value: data});
    commit('handleReceiving', 'internalTransferPreset');
  },

  setWaitingResponse({state, commit}, type) {
    state.meta.timeoutId[type] = setTimeout(() => {
      if (state.meta.isReceived[type] === false) {
        let message = '';
        switch (type) {
          case 'billingPreset':
          case 'tradingPreset':
          case 'internalTransferPreset':
            message = NOTIFICATION_WARNING_MESSAGE_NO_ENOUGH_DATA;
            break;
          case 'profile':
          case 'roles':
            commit('appState/showReloadSuggestion', {isNoDataMessage: true, delay: 0}, {root: true});
        }
        if (!message || !state.meta.timeoutId[type]) return;
        return vueInstance.errorMsg(message);
      }
    }, delay);
  },
};

export default {namespaced: true, state, getters, mutations, actions};
