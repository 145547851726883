<template>
  <ex-setting-template class="ex-setting-security">
    <ex-setting-template-row v-if="sms">
      <template #label>2FA via SMS Authorization</template>
      <template>
        <el-switch
          v-loading="isUpdateSmsSetting"
          v-model="SMS"
          active-color="#67c23a"
          inactive-color="#f56c6c"
          @change="togglerSMS"
        />
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>2FA via Google Authenticator Authorization</template>
      <template><ex-google-authenticator /></template>
    </ex-setting-template-row>
  </ex-setting-template>
</template>

<script>
// api
import UserApi from '@/api/userService/user';

// component
import ExSettingTemplate from '@/pages/setting/ex-setting-template';
import ExSettingTemplateRow from '@/pages/setting/ex-setting-template-row';
import ExGoogleAuthenticator from '@/components/ex-google-authenticator';

// const
import {UPDATE_USER_SMS_SETTING} from '@/constants/events/currentUser/actions.type';

// util
import {socketRequest} from '@/utils/socket';

export default {
  name: 'ExSettingSecurity',
  components: {ExGoogleAuthenticator, ExSettingTemplateRow, ExSettingTemplate},
  props: {
    profile: {
      type: Object,
      required: true,
    },
    sms: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      SMS: false,
      isUpdateSmsSetting: false,
    };
  },
  watch: {
    profile: {
      handler(newValue) {
        if (newValue.flagSMS !== undefined && newValue.flagSMS !== this.SMS) {
          this.SMS = newValue.flagSMS;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    togglerSMS(checked) {
      socketRequest.call(this, UPDATE_USER_SMS_SETTING,
        () => `2FA via SMS Authorization ${this.SMS ? 'enabled' : 'disabled'}`,
        () => {
          this.SMS = !this.SMS;
        },
        () => {
          this.isUpdateSmsSetting = false;
        },
      );
      this.isUpdateSmsSetting = true;
      UserApi.updateSmsAndGASetting(UPDATE_USER_SMS_SETTING, {
        username: this.profile.username,
        lastName: this.profile.lastName,
        flagSMS: checked,
        flagGA: this.profile.flagGA,
      });
    },
  },
};
</script>
