import { render, staticRenderFns } from "./ex-otc-trading-create.vue?vue&type=template&id=962c0ef8&scoped=true"
import script from "./ex-otc-trading-create.vue?vue&type=script&lang=js"
export * from "./ex-otc-trading-create.vue?vue&type=script&lang=js"
import style0 from "./ex-otc-trading-create.vue?vue&type=style&index=0&id=962c0ef8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "962c0ef8",
  null
  
)

export default component.exports