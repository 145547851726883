<template>
  <el-form :model="form" :rules="rules" ref="form" class="ex-auto-trading-create" label-position="top">
    <ex-alert v-if="!hasTradingData" type="danger">
      {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
    </ex-alert>
    <div class="ex-auto-trading-create__group">
      <el-form-item label="Currency" prop="currency">
        <el-radio-group v-model="form.currency">
          <el-radio-button v-for="item in currencyList" :key="item" :label="item" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Pair" prop="pair">
        <el-radio-group v-model="form.pair">
          <el-radio-button v-for="item in pairList" :key="item.value" :label="item.value">
            {{item.label}}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Trade side" prop="type">
        <el-radio-group v-model="form.type" class="ex-auto-trading-create__radio-group">
          <el-radio-button
            v-for="item in TRADE_SIDE"
            :key="item"
            :label="item"
            class="ex-auto-trading-create__radio-button"
          >
            {{upperFirst(item)}}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Price Type" prop="priceType">
          <el-radio-group v-model="form.priceType" class="ex-auto-trading-create__radio-group">
            <el-radio-button
              v-for="item in PRICE_TYPE"
              :key="item.value"
              :label="item.value"
              class="ex-auto-trading-create__radio-button"
            >
              {{item.label}}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
    </div>
    <el-form-item
      :label="`Amount of ${firstCurrency}`"
      :rules="[form.secondCurrencyAmount === null ? ruleRequired : {}]"
      prop="firstCurrencyAmount"
    >
      <el-input v-model="form.firstCurrencyAmount" ref="firstCurrencyAmount" id="firstCurrencyAmount">
        <el-button slot="append" icon="el-icon-plus" @click="handleMoveToMax(firstCurrency)">All/Max</el-button>
      </el-input>
    </el-form-item>
    <el-form-item
      :label="`Amount of ${secondCurrency}`"
      :rules="[form.firstCurrencyAmount === null ? ruleRequired : {}]"
      prop="secondCurrencyAmount"
    >
      <el-input v-model="form.secondCurrencyAmount" ref="secondCurrencyAmount" id="secondCurrencyAmount">
        <el-button slot="append" icon="el-icon-plus" @click="handleMoveToMax(secondCurrency)">All/Max</el-button>
      </el-input>
    </el-form-item>
    <ex-alert v-if="!hasTradingData" type="danger">
      {{WARNING_MESSAGE_NO_ENOUGH_DATA}}
    </ex-alert>
    <el-button
      class="ex-auto-trading-create__button"
      type="primary"
      :loading="isLoading"
      :disabled="!hasTradingData"
      @click="createRequest"
    >
      Create Order
    </el-button>
  </el-form>
</template>

<script>
// component
import ExAlert from '@/components/ex-alert';

// api
import AutoTradingApi from '@/api/requestService/autoTrading.api';

// utils
import inputmasks from '@/utils/inputmasks';
import {cutPrecisionByCurrency, numberFromLocaleString} from '@/utils/converters/currency';
import {createPair} from '@/utils/common';
import {ruleRequired} from '@/utils/elementUITypicalValidation';
import {typicalPostReqSocketRes} from '@/utils/socket';

// app
import {mapGetters, mapState} from 'vuex';
import {upperFirst} from 'lodash';

// const
import {REQUEST_TRANSACTION_TYPES} from '@/constants/common';
import {WARNING_MESSAGE_NO_ENOUGH_DATA} from '@/constants/commonMessage';
import {
  CRYPTO_CURRENCIES,
  FIAT_CURRENCIES,
} from '@/constants/currencies';
import {SPAM_PRICE} from '@/constants/events/store/requests/actions.type';

// setting
const PRICE_TYPE = [
  {label: 'Final price', value: 'finalPrice'},
  {label: 'Reference', value: 'referencePrice'},
];
const TRADE_SIDE = ['buy', 'sell'];
const TYPICAL_CURRENCY_PAIRS = [
  {second: FIAT_CURRENCIES.EUR},
  {second: FIAT_CURRENCIES.USD},
  {second: CRYPTO_CURRENCIES.USDT_ERC20},
];
const AVAILABLE_PAIR = {
  [CRYPTO_CURRENCIES.BTC]: [
    ...TYPICAL_CURRENCY_PAIRS,
    {first: CRYPTO_CURRENCIES.ETH},
  ],
  [CRYPTO_CURRENCIES.ETH]: [
    ...TYPICAL_CURRENCY_PAIRS,
    {second: CRYPTO_CURRENCIES.BTC},
  ],
  [CRYPTO_CURRENCIES.USDT_ERC20]: [
    {second: FIAT_CURRENCIES.EUR},
    {second: FIAT_CURRENCIES.USD},
    {second: CRYPTO_CURRENCIES.USDC},
    {first: CRYPTO_CURRENCIES.BTC},
    {first: CRYPTO_CURRENCIES.ETH},
  ],
  [CRYPTO_CURRENCIES.USDC]: [
    {second: FIAT_CURRENCIES.EUR},
    {second: FIAT_CURRENCIES.USD},
    {first: CRYPTO_CURRENCIES.USDT_ERC20},
  ],
  [CRYPTO_CURRENCIES.USDT_TRC20]: [
    {second: FIAT_CURRENCIES.EUR},
    {second: FIAT_CURRENCIES.USD},
  ],
};

export default {
  name: 'ExAutoTradingCreate',
  components: {ExAlert},
  props: {
    currency: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        currency: '',
        pair: '',
        type: '',
        firstCurrencyAmount: null,
        secondCurrencyAmount: null,
        date: new Date().toISOString().split('T')[0],
        priceType: 'finalPrice',
      },
      rules: {
        currency: [ruleRequired],
        pair: [ruleRequired],
        type: [ruleRequired],
        firstCurrencyAmount: [], // The rules are described in the template
        secondCurrencyAmount: [], // The rules are described in the template
        date: [],
        priceType: [ruleRequired],
      },
      isLoading: false,
      PRICE_TYPE,
      TRADE_SIDE,
      ruleRequired,
      WARNING_MESSAGE_NO_ENOUGH_DATA,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    ...mapState('currentUser', {
      margin: (state) => state.preset?.trading?.margin,
      marginFiat: (state) => state.preset?.trading?.marginFiat,
    }),
    ...mapGetters('currentUser', ['hasTradingData']),
    firstCurrency() {
      return this.form.pair?.split(':')[0] || '';
    },
    secondCurrency() {
      return this.form.pair?.split(':')[1] || '';
    },
    currencyList() {
      return Object.keys(AVAILABLE_PAIR);
    },
    pairList() {
      return AVAILABLE_PAIR[this.form.currency]?.map((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('second')) {
          return createPair(this.form.currency, item.second);
          // eslint-disable-next-line no-prototype-builtins
        } else if (item.hasOwnProperty('first')) {
          return createPair(item.first, this.form.currency);
        }
      });
    },
  },
  watch: {
    'form.currency': {
      handler(newValue, oldValue) {
        this.form.pair = this.pairList[0].value;
        if (newValue === oldValue || newValue === this.$route.params.currency) return;
        this.$router.replace({
          name: 'RequestCreate',
          params: {
            requestType: this.$route.params.requestType,
            type: this.form.type,
            currency: newValue,
          },
        });
      },
    },
    'form.type': {
      handler(newValue, oldValue) {
        if (newValue === oldValue || newValue === this.$route.params.type) return;
        this.$router.replace({
          name: 'RequestCreate',
          params: {
            requestType: this.$route.params.requestType,
            type: newValue,
            currency: this.form.currency,
          },
        });
      },
    },
    'form.firstCurrencyAmount': {
      handler() {
        this.form.secondCurrencyAmount = null;
        this.$nextTick(() => {
          this.$refs.form.clearValidate('secondCurrencyAmount');
        });
      },
    },
    'form.secondCurrencyAmount': {
      handler() {
        this.form.firstCurrencyAmount = null;
        this.$nextTick(() => {
          this.$refs.form.clearValidate('firstCurrencyAmount');
        });
      },
    },
    firstCurrency(value) {
      this.form.firstCurrencyAmount =
        cutPrecisionByCurrency(numberFromLocaleString(this.form.firstCurrencyAmount), value);
      this.addInputMask(value, 'firstCurrencyAmount');
    },
    secondCurrency(value) {
      this.form.secondCurrencyAmount =
        cutPrecisionByCurrency(numberFromLocaleString(this.form.secondCurrencyAmount), value);
      this.addInputMask(value, 'secondCurrencyAmount');
    },
  },
  created() {
    this.form.currency = this.currencyList.includes(this.currency) ? this.currency : this.currencyList[0];
    this.form.pair = this.pairList[0].value;
    this.form.type = this.type;
  },
  mounted() {
    this.addInputMask(this.firstCurrency, 'firstCurrencyAmount');
    this.addInputMask(this.secondCurrency, 'secondCurrencyAmount');
  },
  methods: {
    upperFirst,
    createRequest() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        if (numberFromLocaleString(this.form.firstCurrencyAmount) > 0) {
          return this.createAutoTradingRequest(this.prepareRequest());
        }
        if (numberFromLocaleString(this.form.secondCurrencyAmount) > 0) {
          return this.createAutoTradingRequest(this.prepareRequest(true), true);
        }
      });
    },
    prepareRequest(secondCurrency = false) {
      return {
        currencyPair: this.form.pair,
        amount: secondCurrency ? undefined : numberFromLocaleString(this.form.firstCurrencyAmount),
        amountLast: secondCurrency ? numberFromLocaleString(this.form.secondCurrencyAmount) : undefined,
        type: REQUEST_TRANSACTION_TYPES[this.form.type],
        callbackPrice: SPAM_PRICE,
        margin: this.margin,
        marginFiat: this.marginFiat,
      };
    },
    async createAutoTradingRequest(data, secondCurrency = false) {
      try {
        this.isLoading = true;
        const {requestId} = await typicalPostReqSocketRes({
          context: this,
          event: 'create:auto-trading-request',
          request: secondCurrency
            ? AutoTradingApi.createAutoTradingOrderSecondCurrency
            : AutoTradingApi.createAutoTradingOrderFirstCurrency,
          data,
          localErrorHandling: true,
        });
        this.saveToStorage(requestId);
        await this.$router.push({name: 'Trading', params: {id: requestId, type: 'view'}});
      } catch (e) {
        let {errorMessage} = e;
        if (e.error.code === '19:5') {
          errorMessage = `Minimum order size is ${e?.error?.data?.limit} ${this.secondCurrency}`;
        } else if (e.error.code === '3:246') {
          errorMessage = `
            Transaction limit exceeded, please try to trade in a smaller amount.
            (Max ${e.error.limit} ${this.form.currency})
          `;
        }
        this.errorMsg(errorMessage);
      } finally {
        this.isLoading = false;
      }
    },
    saveToStorage(id) {
      sessionStorage.setItem(`request-${id}`, JSON.stringify({priceType: this.form.priceType, timeout: null}));
    },
    handleMoveToMax(currency) {
      const max = this.getBalance(currency);
      const amount = max > 0 && max < 1 ? max.toFixed(10) : max;
      if (this.firstCurrency === currency) {
        this.form.secondCurrencyAmount = null;
        this.form.firstCurrencyAmount = amount;
      } else if (this.secondCurrency === currency) {
        this.form.firstCurrencyAmount = null;
        this.form.secondCurrencyAmount = amount;
      }
    },
    getBalance(currency) {
      const balance = this.profile.balance.find((item) => item.currency === currency);
      return balance?.amount < 0 ? 0 : balance.amount;
    },
    addInputMask(currency, ref) {
      this.$nextTick(() => {
        inputmasks.addMaskByCurrency(currency, this.$refs[ref]);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-auto-trading-create {
  &__button {
    margin-top: 10px;
    width: 100%;
  }
  ::v-deep .el-form-item {
    padding: 0 6px;
  }
}
</style>
