<template>
  <ex-setting-template class="ex-setting-integration">
    <ex-setting-template-row>
      <template #label>Tunell Gateway token</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="updateTunnelGatewayApiKey"
          >
            <el-button
              :loading="isUpdatingTunnelGatewayApiKey"
              slot="reference"
              :disabled="isNoTunnelGatewayApiKey"
            >
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__key">
            <div v-show="isNoTunnelGatewayApiKey">You don't have an Tunell Gateway account</div>
            <div v-show="!isNoTunnelGatewayApiKey" class="ex-setting-integration__row">
              <el-button
                icon="el-icon-view"
                type="text"
                circle
                @click="isShowTunnelGatewayApiKey = !isShowTunnelGatewayApiKey"
              />
              <div  class="ex-setting-integration__key-value">
                {{isShowTunnelGatewayApiKey ? tunnelGatewayApiKey : '******************************'}}
              </div>
              <ex-copy class="ex-setting-integration__copy" :value="tunnelGatewayApiKey">
                <i class="el-icon-copy-document" />
              </ex-copy>
            </div>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Call back token for Tunell Gateway</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="handleGenerateWebhookTokenTunnel"
          >
            <el-button :loading="webHookToken.isLoadingTunnel" slot="reference">
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__token">
            {{webHookToken.tunnelValue || '********************************'}}
            <ex-copy class="ex-setting-integration__copy" :value="webHookToken.tunnelValue">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <ex-setting-template-row>
      <template #label>Call back token for Trading</template>
      <template>
        <div class="ex-setting-integration__row">
          <el-popconfirm
            title="Token will be updated!"
            confirm-button-text="I agree"
            cancel-button-text="No, Thanks"
            @confirm="generateTradingWebhookToken"
          >
            <el-button :loading="webHookToken.isLoadingTrading" slot="reference">
              Generate
            </el-button>
          </el-popconfirm>
          <div class="ex-setting-integration__token">
            {{webHookTokenValue || '********************************'}}
            <ex-copy class="ex-setting-integration__copy" :value="webHookTokenValue">
              <i class="el-icon-copy-document" />
            </ex-copy>
          </div>
        </div>
      </template>
    </ex-setting-template-row>
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible="visiblePopup"
      class="ex-setting-integration__popup"
      width="60%"
    >
      <template #title>
        <div class="ex-setting-integration__popup-title">
          New API key and API secret
        </div>
      </template>
      <div class="ex-setting-integration__popup-content">
        <div class="ex-setting-integration__popup-row">
          <div class="ex-setting-integration__popup-subtitle">API key</div>
          <div class="ex-setting-integration__popup-value">
            {{tunnelGatewayApiKey}}
          </div>
        </div>
        <div class="ex-setting-integration__popup-row">
          <div class="ex-setting-integration__popup-subtitle">API secret</div>
          <div class="ex-setting-integration__popup-value">
            {{tunnelGatewaySecret}}
          </div>
        </div>
        <div class="ex-setting-integration__popup-row">
          <ex-alert type="warning">
            <div class="ex-setting-integration__popup-label">WARNING</div>
            <div>You will see the API Secret only once, so make sure you saved it securely.</div>
            <div>If you compromise or lose your API Key or API Secret, use the re-genereation option.</div>
          </ex-alert>
        </div>
        <div class="ex-setting-integration__popup-row ex-setting-integration__popup-action">
          <el-button type="warning" @click="showPopup">
            I saved API Key and API Secret
          </el-button>
        </div>
      </div>
    </el-dialog>
  </ex-setting-template>
</template>

<script>
// components
import ExSettingTemplate from '@/pages/setting/ex-setting-template';
import ExSettingTemplateRow from '@/pages/setting/ex-setting-template-row';
import ExAlert from '@/components/ex-alert';
import ExCopy from '@/components/ex-copy';

// const
import {UPDATE_USER_WEBHOOK_TRADING} from '@/constants/events/trading/actions.type';
import {UPDATE_TUNNEL_API_KEY, UPDATE_TUNNEL_WEBHOOK} from '@/constants/events/tunnel/actions.type';

// utils
import {socketRequest} from '@/utils/socket';

// api
import UserApi from '@/api/userService/user';
import tunnelGateway from '@/api/tunnel.api';

// lib
import {mapState} from 'vuex';

export default {
  name: 'ExSettingIntegration',
  components: {ExAlert, ExCopy, ExSettingTemplateRow, ExSettingTemplate},
  props: {
    profile: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tunnelGatewayApiKey: '',
      tunnelGatewaySecret: '',
      webHookToken: {
        value: '',
        tunnelValue: '',
        isSaved: false,
        isLoadingTrading: false,
        isLoadingTunnel: false,
        loading: false,
      },
      isUpdateSmsSetting: false,
      isUpdatingTunnelGatewayApiKey: false,
      isShowTunnelGatewayApiKey: false,
      visiblePopup: false,
    };
  },
  computed: {
    ...mapState('currentUser', ['preset']),
    webHookTokenValue() {
      return this.webHookToken.isSaved ? this.webHookToken.value : '';
    },
    isNoTunnelGatewayApiKey() {
      return this.tunnelGatewayApiKey === false;
    },
  },
  created() {
    this.tunnelGatewayApiKey = this.preset.billing.apiKey ? this.preset.billing.apiKey : false;
  },
  methods: {
    handleGenerateWebhookTokenTunnel() {
      socketRequest.call(this, UPDATE_TUNNEL_WEBHOOK,
        (payload) => {
          this.webHookToken.tunnelValue = payload.webhookToken;
        },
        () => {},
        () => {
          this.webHookToken.isLoadingTunnel = false;
        },
      );
      this.webHookToken.isLoadingTunnel = true;
      tunnelGateway.updateTunnelWebhook(UPDATE_TUNNEL_WEBHOOK);
    },
    generateTradingWebhookToken() {
      this.webHookToken.isSaved = false;
      this.webHookToken.isLoadingTrading = true;

      UserApi.generateWebhookToken().then(({data}) => {
        this.webHookToken.value = data.webHookToken;
        socketRequest.call(this, UPDATE_USER_WEBHOOK_TRADING,
          (payload) => {
            if (payload.status) {
              this.webHookToken.isSaved = true;
              return 'Webhook token updated for trading';
            } else {
              return 'Webhook token not updated for trading';
            }
          },
          () => {},
          () => {
            this.webHookToken.isLoadingTrading = false;
          },
        );
        UserApi.updateWebhookTokenTrading(UPDATE_USER_WEBHOOK_TRADING, {
          webHookToken: data.webHookToken,
          userUid: this.profile.id,
        });
      });
    },
    updateTunnelGatewayApiKey() {
      socketRequest.call(this, UPDATE_TUNNEL_API_KEY,
        (payload) => {
          this.tunnelGatewayApiKey = payload.apiKey;
          this.tunnelGatewaySecret = payload.apiSecret;
          this.visiblePopup = true;
        },
        () => {},
        () => {
          this.isUpdatingTunnelGatewayApiKey = false;
        },
      );
      this.isUpdatingTunnelGatewayApiKey = true;
      tunnelGateway.updateTunnelApiKey(UPDATE_TUNNEL_API_KEY);
    },
    showPopup() {
      this.visiblePopup = !this.visiblePopup;
      this.tunnelGatewaySecret = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-setting-integration {
  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__token, &__key {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 10px;
    margin-top: -2px;
    font-size: 16px;
    color: #333;
  }
  &__key {
    &-value {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 210px;
    }
    ::v-deep .el-button--text {
      color: #606266;
    }
  }
  &__copy {
    margin-left: 7px;
  }
  &__popup {
    &-row {
      padding-bottom: 16px;
    }
    &-title  {
      font-size: 22px;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 8px;
    }
    &-value {
      font-size: 16px;
    }
    &-action {
      display: flex;
      justify-content: flex-end;
    }
    &-label {
      font-weight: 600;
    }
  }
}
.el-icon-copy-document {
  color: #67c23a;
  font-size: 24px;
  font-weight: 500;
}
</style>
