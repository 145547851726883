<template>
  <div class="ga">
    <el-popover v-model="valueProp" popper-class="ga__popover" placement="right" width="262">
      <template slot="reference">
        <el-switch
          :value="valueSwitcher"
          @change="handleChangeSwitcher"
          :class="{
            'ga__switch_disable': status === 0,
            'ga__switch_disable-prepare': status === 2,
            'ga__switch_active-prepare': status === 3,
            'ga__switch_active': status === 1,
          }"
          class="ga__switch"
        />
      </template>
      <div class="ga__wrap" v-show="valueProp">
        <el-form ref="form" :model="form" :rules="rules" @keyup.enter.native="handleEnable">
          <div class="ga__item">
            <div class="ga__description">1. Scan the qr code in application of google authenticator:</div>
            <div class="ga__control ga__control_center">
              <div v-loading="isFirstTime && qrcodeLoading" class="ga__qrcode">
                <img v-if="isFirstTime" :src="qrcode" alt="qrcode" style="width: 100%;" />
                <div v-else class="ga__qrcode-success">
                  <i class="el-icon-check" />
                  <br />
                  You have already added google authenticator
                </div>
              </div>
            </div>
            <div v-if="textCode" class="ga__text-code">
              <el-input :value="textCode" :type="isTextCodeShow ? 'text' : 'password'" disabled>
                <i class="el-icon-view" slot="suffix" @click="isTextCodeShow = !isTextCodeShow"></i>
              </el-input>
            </div>
          </div>
          <div class="ga__item">
            <div class="ga__description">2. Enter your Google Authenticator code in the input box below:</div>
            <div class="ga__control">
              <el-form-item ref="code-item" prop="code">
                <el-input ref="code" :value="form.code" placeholder="Example: 345334" @input="handleChangeCode" />
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div class="ga__actions">
          <el-button class="ga__actions-button" @click.prevent="valueProp = false">CANCEL</el-button>
          <el-button
            type="success"
            :loading="confirmLoading"
            :disabled="(isFirstTime && qrcodeLoading) ||
              !(this.$refs.code &&
                this.$refs.code.validateState &&
                this.$refs.code.validateState === 'success')"
            @click.prevent="handleEnable"
          >
            ENABLE
          </el-button>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
// api
import UserApi from '@/api/userService/user';

// utils
import {socketRequest} from '@/utils/socket';

// lib
import {mapState} from 'vuex';

// const
import {CONFIRM_USER_2FAGA, UPDATE_USER_GA_SETTING} from '@/constants/events/currentUser/actions.type';

export default {
  name: 'ExGoogleAuthenticator',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const validateIncorrectCode = (rule, value, callback) => {
      if (this.codeErrorMessage) {
        callback(new Error(this.codeErrorMessage));
      } else {
        callback();
      }
    };

    return {
      rules: {
        code: [{
          required: true,
          message: 'Field is required',
          trigger: 'change',
        }, {
          validator: validateIncorrectCode,
          trigger: 'change',
        }],
      },
      form: {
        code: null,
      },
      codeErrorMessage: null,
      confirmLoading: false,
      isSwitch: false,
      valueProp: false,
      valueSwitcher: false,
      qrcode: null,
      textCode: '',
      isTextCodeShow: false,
      qrcodeLoading: true,
    };
  },
  computed: {
    ...mapState('currentUser', ['profile']),
    isFirstTime() {
      if (this.profile.flagGA) return false;

      return this.qrcode !== undefined;
    },
    status() {
      if (this.profile.flagGA) {
        return this.valueProp ? 3 : 1;
      }

      return this.valueProp ? 2 : 0;
    },
    isActive() {
      const statuses = {0: false, 2: false, 3: true, 1: true};

      return statuses[this.status];
    },
  },
  methods: {
    handleChangeSwitcher(v) {
      if ([2, 3].includes(this.status)) return;

      this.valueSwitcher = v;
    },
    handleChangeCode(v) {
      this.form.code = v;
      this.codeErrorMessage = null;
    },
    handleEnable() {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          this.$emit('enable');

          this.confirmFlag();
        }
      });
    },
    updateFlag() {
      socketRequest.call(this, UPDATE_USER_GA_SETTING,
        (payload) => {
          this.qrcode = payload.QRCodeUrl;
          this.textCode = payload.code;
        },
      );
      UserApi.updateSmsAndGASetting(UPDATE_USER_GA_SETTING, {
        username: this.profile.username,
        lastName: this.profile.lastName,
        flagSMS: this.profile.flagSMS,
        flagGA: !this.isActive,
      });
    },
    confirmFlag() {
      socketRequest.call(this, CONFIRM_USER_2FAGA,
        () => {
          this.valueProp = false;
          return `2FA via GA Authorization ${this.profile.flagGA ? 'enabled' : 'disabled'}`;
        },
        (payload) => {
          if (payload.error.code === '2:223') {
            this.$refs.form.validate((v) => v);
            this.$refs.code.focus();
          }
        },
        () => {
          this.confirmLoading = false;
        },
      );
      this.confirmLoading = true;
      UserApi.confirm2FAGA(CONFIRM_USER_2FAGA, {userId: this.profile.id, code: this.form.code});
    },
  },

  watch: {
    value: {
      handler(v) {
        this.valueProp = v;
      },
      immediate: true,
    },
    valueProp: {
      handler(v) {
        // initial
        // start loading
        this.qrcodeLoading = true;
        this.qrcode = '';

        this.$emit('input', v);

        if (v) {
          this.updateFlag();
          this.$nextTick(() => this.$refs.code.focus());
        } else {
          this.valueSwitcher = !this.valueSwitcher;
          this.form.code = null;
        }
      },
      immediate: true,
    },
    isActive: {
      handler(v) {
        this.valueSwitcher = Boolean(v);
      },
      immediate: true,
    },
    qrcode(v) {
      this.qrcode = v;

      // stop loading
      if (v) {
        setTimeout(() => {
          this.qrcodeLoading = false;
        }, 1500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ga {
  ::v-deep {
    .ga__switch {
      &_disable .el-switch__core {
        border-color: #F56C6C;
        background-color: #F56C6C;
      }
      &_disable-prepare .el-switch__core {
        border-color: #E6A23C;
        background-color: #E6A23C;
      }
      &_active-prepare .el-switch__core {
        border-color: #E6A23C;
        background-color: #E6A23C;
      }
      &_active .el-switch__core {
        border-color: #67C23A;
        background-color: #67C23A;
      }
    }
  }
  &__item {
    padding-bottom: 20px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  &__description {
    font-style: italic;
    padding-bottom: 10px;
  }
  &__control {
    &_center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__qrcode {
    background: white;
    padding: 15px;
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-success {
      text-align: center;
      word-break: normal;
      text-transform: uppercase;
      color: #67C23A;
    }
  }
  &__text-code {
    text-align: center;
    margin-top: 20px;
    font-size: 18px;

    ::v-deep .el-input.is-disabled input {
      background: transparent;
      opacity: 1;
      color: #555;
      border: 1px solid #999;
      cursor: text;
    }
    ::v-deep .el-input__inner {
      text-align: center;
    }
    ::v-deep .el-input__suffix {
      display: flex;
      align-items: center;
      padding-right: 5px;
      cursor: pointer;
      color: #777;
      transition: color 0.3s;

      &:hover {
        color: #333;
        transition: color 0.3s;
      }
    }
  }
  &__qrcode-success {
    .el-icon-check {
      font-size: 38px;
      color: #67c23a;
    }
  }
  &__actions {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  &__actions-button {
    border-width: 0;
    background: transparent;
  }
}
</style>
<style lang="scss">
.ga__popover {
  background-color: #FAECD8;
  box-shadow: none;
  border: 1px solid #E6A23C;

  .popper__arrow::after {
    left: 0 !important;
    border-right-color: #E6A23C !important;
  }
}
</style>
