<template>
  <div class="ex-auto-trading-container">
    <ex-auto-trading
      :currency-left="currencyLeft"
      :date-label="dateLabel"
      :amount-left="amountLeft"
      :label-left="labelLeft"
      :currency-right="currencyRight"
      :amount-right="amountRight"
      :label-right="labelRight"
      :time="time"
      :small-cycle="smallCycle"
      :has-stop="hasStop"
      :display-price="formatCurrency(displayPrice, 'crypto')"
      :info="info"
      :status-info="statusInfo"
      :disabled-accept="isDisabledAccept"
      :disabled-cancel="!inProgress"
      :is-loading="isLoading"
      :request-id="request.id"
      :show-report="showReport"
      @accept="acceptAuto"
      @cancel="cancelAuto"
      @timeout="timeout"
    />
  </div>
</template>

<script>
// components
import ExAutoTrading from '@/pages/requests/auto-trading/ex-auto-trading';

// lib
import momentTz from 'moment-timezone';

// api
import TradingApi from '@/api/trading.api';
import AutoTradingApi from '@/api/requestService/autoTrading.api';

// utils
import {formatCurrency, getPairCurrencies, isFiat} from '@/utils/converters/currency';
import {socketRequest} from '@/utils/socket';
import {utilTimeFormat} from '@/utils/converters/time';

// app
import {mapGetters} from 'vuex';

// mixin
import AutoTradingMixin from '@/mixins/AutoTradingMixin';

// const
import {
  SPAM_PRICE,
} from '@/constants/events/store/requests/actions.type';
import {
  INIT_SUBSCRIBE_SPAM_PRICE_AUTO_TRADING,
  ACCEPT_AUTO_SECOND_CURRENCY,
  ACCEPT_AUTO_FIRST_CURRENCY,
} from '@/constants/events/requests/actions.type';
import {FETCH_TRADING_MARGIN} from '@/constants/events/trading/actions.type';
import {
  EXCHANGE_STATUS,
  EXCHANGE_STATUS_TYPES,
  EXCHANGE_TRANSACTION_STATUS,
  REQUEST_TRANSACTION_TYPES,
  REQUEST_TRANSACTION_TYPES as LEFT_ACTION_TYPES,
} from '@/constants/common';
import {CURRENCY_TYPE, USDT_LIKE_CURRENCIES_LIST} from '@/constants/currencies';

// labels
const HISTORY_LABELS = {
  [EXCHANGE_STATUS.REJECT]: () => 'Canceled by Trader',
  [EXCHANGE_STATUS.CANCELLED]: () => 'Canceled by Client',
  [EXCHANGE_STATUS.CANCELED_BY_TIMEOUT]: () => 'Canceled by timeout',
  [EXCHANGE_STATUS.REVERSED]: (request) => `Reversed ${utilTimeFormat(request.updateAt)}`,
  [EXCHANGE_STATUS.REJECT_AUTOMATIC]: () => 'Rejected by automatic',
};

export default {
  name: 'ExAutoTradingContainer',
  components: {ExAutoTrading},
  mixins: [AutoTradingMixin],
  props: {
    request: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      hasStop: false,
      isLoading: false,
      smallCycle: {
        from: 0,
        to: 0,
      },
      margins: {
        margin: null,
        marginFiat: null,
        hash: null,
      },
      rightActionType: {
        1: 'Spend',
        2: 'Final amount',
      },
      priceType: 'finalPrice',
    };
  },
  computed: {
    ...mapGetters('requests', ['getSpamPrice']),
    isNotEnoughData() {
      return !this.spamPrice || this.margin === null;
    },
    inProgress() {
      return EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(this.request.transactionStatus);
    },
    isDisabledAccept() {
      return !(this.inProgress && this.spamPrice && this.margins.hash);
    },
    showReport() {
      return [
        EXCHANGE_STATUS.ACCEPT_BY_USER,
        EXCHANGE_STATUS.ACCEPT_AUTOMATIC,
        EXCHANGE_STATUS.REVERSED,
      ].includes(this.request.transactionStatus);
    },
    isBuy() {
      return this.request.transactionType === REQUEST_TRANSACTION_TYPES.buy;
    },
    isFirstCurrencyOfPair() {
      return this.request.amountFiat === null;
    },
    spamPrice() {
      return this.getSpamPrice(this.request.id);
    },
    currencyLeft() {
      return getPairCurrencies(this.request.currencyPair)[0];
    },
    currencyRight() {
      return getPairCurrencies(this.request.currencyPair)[1];
    },
    labelLeft() {
      return LEFT_ACTION_TYPES[this.request.transactionType];
    },
    labelRight() {
      return this.rightActionType[this.request.transactionType];
    },
    dateLabel() {
      return momentTz.tz(this.request.updateAt.date, this.request.updateAt.timezone)
        .format('MMM DD, YYYY, hh:mm:ss');
    },
    amountLeft() {
      if (this.isFirstCurrencyOfPair) {
        return `${this.isBuy ? '+' : '-'}${formatCurrency(this.request.amount, '', this.numberCharactersAfterCommaLeft)}`;
      } else { // SECOND
        return `${this.isBuy ? '+' : '-'}${formatCurrency(this.request.amountFiat / this.displayPrice, '', this.numberCharactersAfterCommaLeft)}`;
      }
    },
    amountRight() {
      if (this.isFirstCurrencyOfPair) {
        return `${this.isBuy ? '-' : '+'}${formatCurrency(this.request.amount * this.displayPrice, '', this.numberCharactersAfterCommaRight)}`;
      } else { // SECOND
        return `${this.isBuy ? '-' : '+'}${formatCurrency(this.request.amountFiat, '', this.numberCharactersAfterCommaRight)}`;
      }
    },
    numberCharactersAfterCommaLeft() {
      if (isFiat(this.currencyLeft)) return 2;
      if (USDT_LIKE_CURRENCIES_LIST.includes(this.currencyLeft)) return 6;
      return 8;
    },
    numberCharactersAfterCommaRight() {
      if (isFiat(this.currencyRight)) return 2;
      if (USDT_LIKE_CURRENCIES_LIST.includes(this.currencyRight)) return 6;
      return 8;
    },
    displayPrice() {
      return this.priceType === 'finalPrice' ? this.finalPrice : this.referencePrice;
    },
    finalPrice() {
      if (this.request.price) return this.request.price;
      if (this.isNotEnoughData) return 0;

      if (this.isBuy) {
        return Number((this.spamPrice.price + this.spamPrice.price * (this.margin / 100)).toFixed(8));
      } else { // SELL
        return Number((this.spamPrice.price - this.spamPrice.price * (this.margin / 100)).toFixed(8));
      }
    },
    referencePrice() {
      if (this.isNotEnoughData) return 0;

      return Number((this.spamPrice.price).toFixed(8));
    },
    finalAmount() {
      return this.isFirstCurrencyOfPair
        ? this.request.amount * this.finalPrice
        : this.finalPrice === 0 ? 0 : this.request.amountFiat / this.finalPrice;
    },
    referenceAmount() {
      return this.isFirstCurrencyOfPair
        ? this.request.amount * this.referencePrice
        : this.referencePrice === 0 ? 0 : this.request.amountFiat / this.referencePrice;
    },
    info() {
      if (EXCHANGE_STATUS_TYPES.COMPLETED.includes(this.request.transactionStatus) ||
          EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(this.request.transactionStatus)) {
        return `
        ${this.referencePrice === 0
          ? ''
          : `<div>REFERENCE PRICE: ${formatCurrency(this.referencePrice, CURRENCY_TYPE.CRYPTO)}</div>`}
        ${this.referenceAmount === 0
          ? ''
          : `<div>REFERENCE AMOUNT: ${formatCurrency(this.referenceAmount, '', this.isFirstCurrencyOfPair ? this.numberCharactersAfterCommaRight : this.numberCharactersAfterCommaLeft)}</div>`}
        ${this.referencePrice === 0
          ? ''
          : `<div>FEE (%): ${this.margin}%</div>
              <div>FEE: ${formatCurrency((this.finalAmount - this.referenceAmount), CURRENCY_TYPE.CRYPTO)}</div>`}
        <div>FINAL PRICE: ${formatCurrency(this.finalPrice, CURRENCY_TYPE.CRYPTO)}</div>
        <div>FINAL AMOUNT: ${formatCurrency(this.finalAmount, '', this.isFirstCurrencyOfPair ? this.numberCharactersAfterCommaRight : this.numberCharactersAfterCommaLeft)}</div>`;
      } else {
        return HISTORY_LABELS[this.request.transactionStatus](this.request);
      }
    },
    statusInfo() {
      return this.inProgress
        ? ''
        : EXCHANGE_STATUS_TYPES.COMPLETED.includes(this.request.transactionStatus)
          ? 'success'
          : 'danger';
    },
    time() {
      return (this.request.updateAt && this.request.timeoutAt)
        ? {
          from: momentTz.tz(this.request.updateAt.date, this.request.updateAt.timezone).valueOf(),
          to: momentTz.tz(this.request.timeoutAt.date, this.request.timeoutAt.timezone).valueOf(),
        }
        : {from: 0, to: 0};
    },
    margin() {
      return isFiat(this.currencyRight) ? this.margins.marginFiat : this.margins.margin;
    },
  },
  watch: {
    'spamPrice.time': {
      handler() {
        this.restartSmallCycle();
      },
    },
  },
  created() {
    if (this.inProgress) {
      if (this.moreThen(10000)) {
        this.initSubscribeSpamPrice();
        this.initiateCheckForSomethingWrong();
        this.restartSmallCycle();
      }
      this.fetchMargin();
    } else {
      this.stopTimer();
    }
    this.saveTimeoutStorage();
    this.removeExpiredPriceTypes();
    if (this.inProgress) {
      const {priceType} = this.getPriceTypeStorage();
      if (priceType) {
        this.priceType = priceType;
        if (priceType === 'referencePrice') {
          this.rightActionType[2] = 'Reference amount';
        }
      }
    }
  },
  methods: {
    formatCurrency,
    saveTimeoutStorage() {
      const {priceType, timeout} = this.getPriceTypeStorage();

      if (timeout === null && this.request.timeoutAt) {
        const timeout = momentTz.tz(
          this.request.timeoutAt.date,
          this.request.timeoutAt.timezone,
        ).valueOf();
        sessionStorage.setItem(
          `request-${this.request.id}`,
          JSON.stringify({priceType, timeout}),
        );
      }
    },
    getPriceTypeStorage() {
      const serializedData = sessionStorage.getItem(`request-${this.request.id}`);
      if (serializedData !== null) {
        const data = JSON.parse(serializedData);
        return {priceType: data.priceType, timeout: data.timeout};
      }
      return {priceType: null, timeout: null};
    },
    removePriceTypeStorage() {
      sessionStorage.removeItem(`request-${this.request.id}`);
    },
    removeExpiredPriceTypes() {
      for (const key in sessionStorage) {
        if (!Object.prototype.hasOwnProperty.call(sessionStorage, key)) {
          continue;
        }
        if (key.includes('request-')) {
          const serializedData = sessionStorage.getItem(key);
          if (serializedData) {
            const data = JSON.parse(serializedData);
            if (data.timeout === null || (data.timeout - Date.now() <= 0)) {
              sessionStorage.removeItem(key);
            }
          }
        }
      }
    },
    cancelAuto() {
      this.mix_autoTradingMixin_cancelOrder(this.request.id, () => {}, !this.isFirstCurrencyOfPair);
    },
    acceptAuto() {
      if (this.isFirstCurrencyOfPair) {
        this.acceptAutoFirstCurrency();
      } else { // SECOND
        this.acceptAutoSecondCurrency();
      }
    },
    acceptAutoFirstCurrency() {
      socketRequest.call(this, ACCEPT_AUTO_FIRST_CURRENCY,
        () => {
          this.stopTimer();
          this.removePriceTypeStorage();
          return 'Order filled successfully';
        },
        () => {},
        () => {
          this.isLoading = false;
        },
      );
      this.isLoading = true;
      AutoTradingApi.acceptAutoFirstCurrencyOrder(ACCEPT_AUTO_FIRST_CURRENCY, {
        requestId: this.request.id,
        price: this.spamPrice.price,
        margin: this.margins.margin,
        marginFiat: this.margins.marginFiat,
        hash: this.spamPrice.hash,
        marginHash: this.margins.hash,
        fullPrice: this.finalPrice,
      });
    },
    acceptAutoSecondCurrency() {
      socketRequest.call(this, ACCEPT_AUTO_SECOND_CURRENCY,
        () => {
          this.stopTimer();
          this.removePriceTypeStorage();
          return 'Order filled successfully';
        },
        () => {},
        () => {
          this.isLoading = true;
        },
      );
      this.isLoading = true;
      AutoTradingApi.acceptAutoSecondCurrencyOrder(
        ACCEPT_AUTO_SECOND_CURRENCY,
        {
          requestId: this.request.id,
          price: this.spamPrice.price,
          margin: this.margins.margin,
          marginFiat: this.margins.marginFiat,
          hash: this.spamPrice.hash,
          marginHash: this.margins.hash,
          fullPrice: this.finalPrice,
        },
      );
    },
    initSubscribeSpamPrice() {
      AutoTradingApi.initSpamPriceAutoRequest(
        INIT_SUBSCRIBE_SPAM_PRICE_AUTO_TRADING,
        {
          requestId: this.request.id,
          amount: this.isFirstCurrencyOfPair ? this.request.amount : this.request.amountFiat,
          currencyPair: this.request.currencyPair,
          requestType: this.request.transactionType,
          amountType: this.isFirstCurrencyOfPair ? 1 : 2,
          callbackPrice: SPAM_PRICE,
        });
    },
    fetchMargin() {
      socketRequest.call(this, FETCH_TRADING_MARGIN,
        (payload) => {
          this.margins.marginFiat = payload.marginFiat;
          this.margins.margin = payload.margin;
          this.margins.hash = payload.hash;
        },
      );
      TradingApi.fetchMarginAuto(FETCH_TRADING_MARGIN, {requestId: this.request.id});
    },
    restartSmallCycle() {
      this.smallCycle = {from: Date.now(), to: Date.now() + 9500};
    },
    stopTimer() {
      this.hasStop = true;
    },
    initiateCheckForSomethingWrong() {
      setTimeout(() => {
        if (this.isNotEnoughData) return this.errorMsg('Internal error, please try to trade later.');
      }, 60 * 1000);
    },
    moreThen(val) {
      const timeout = momentTz.tz(this.request.timeoutAt.date, this.request.timeoutAt.timezone).valueOf();
      return (timeout - Date.now()) > val;
    },
    timeout() {
      this.request.transactionStatus = EXCHANGE_TRANSACTION_STATUS.CANCELED_BY_TIMEOUT;
    },
  },
};
</script>
