// error source
import srv2 from '@/utils/socketError/errorSources/2';
import srv3 from '@/utils/socketError/errorSources/3';
import srv4 from '@/utils/socketError/errorSources/4';
import srv5 from '@/utils/socketError/errorSources/5';
import srv6 from '@/utils/socketError/errorSources/6';
import srv9 from '@/utils/socketError/errorSources/9';
import srv15 from '@/utils/socketError/errorSources/15';
import srv16 from '@/utils/socketError/errorSources/16';
import srv19 from '@/utils/socketError/errorSources/19';
import srv25 from '@/utils/socketError/errorSources/25';
import srv33 from '@/utils/socketError/errorSources/33';
import srv36 from '@/utils/socketError/errorSources/36';
import srv38 from '@/utils/socketError/errorSources/38';
import defaultErrorByEvent from '@/utils/socketError/errorSources/defaultErrorByEvent';
import errorByEvent from '@/utils/socketError/errorSources/errorByEvent';
import excludedErrorByEvent from '@/utils/socketError/errorSources/excludedErrorByEvent';
import validationErrors from '@/utils/socketError/errorSources/validationErrors';

export default {
  2: {...srv2},
  3: {...srv3},
  4: {...srv4},
  5: {...srv5},
  6: {...srv6},
  9: {...srv9},
  15: {...srv15},
  16: {...srv16},
  19: {...srv19},
  25: {...srv25},
  33: {...srv33},
  36: {...srv36},
  38: {...srv38},
  ERROR_BY_EVENT: {...errorByEvent},
  VALIDATION_ERROR: {...validationErrors},
  DEFAULT_ERROR_BY_EVENT: {...defaultErrorByEvent},
  EXCLUDED_ERROR_BY_EVENT: {...excludedErrorByEvent},
};
