<template>
  <div class="ex-open-orders">
    <el-table :data="table" v-loading="isFetching" stripe>
      <el-table-column min-width="125" label="Timestamp">
        <template v-slot="{row}">
          {{utilTimeFormatOrMessage(row.createAt)}}
        </template>
      </el-table-column>
      <el-table-column label="Pair">
        <template v-slot="{row}">
          {{getPairLabel(row.currencyPair)}}
        </template>
      </el-table-column>
      <el-table-column min-width="70px" label="Client side">
        <template v-slot="{row}">
          {{orderTypeLabel(row.transactionType)}}
        </template>
      </el-table-column>
      <el-table-column label="Amount">
        <template v-slot="{row}">
          {{
            EXCHANGE_PRICE_BEHAVIOUR_TYPES.MARKET === row.priceBehaviourType
              ? 'Market'
              : formatCurrency(row.amount, CURRENCY_TYPE.CRYPTO)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Last Price">
        <template v-slot="{row}">
          {{
            row.priceBehaviourType === EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO
              ? '-'
              : formatCurrency(row.price, CURRENCY_TYPE.FIAT)
          }}
        </template>
      </el-table-column>
      <el-table-column min-width="95px" label="Status">
        <template v-slot="{row}">
          {{getRequestStatusName(row.transactionStatus, row.priceBehaviourType)}}
        </template>
      </el-table-column>
      <el-table-column min-width="65px" label="Timeout">
        <template v-slot="{row}">
          <ex-countdown
            v-if="row.timeoutAt"
            :time-data="getPeriod(row.timeoutAt)"
            :has-stop="!EXCHANGE_STATUS_TYPES.IN_PROGRESS.includes(row.transactionStatus)"
          />
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column min-width="150px" label="Actions">
        <template v-slot="{row}">
          <el-button
            @click="$router.push({name: 'Trading', params: {id: row.id, type: 'view'}})"
            type="warning"
            size="mini"
          >
            Details
          </el-button>
          <el-button
            :loading="mix_exchangeMixin.isCanceling"
            @click="cancel(row)"
            size="mini"
            type="danger"
          >
            Cancel
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// components
import ExCountdown from '@/components/ex-countdown';

// utils
import {orderTypeLabel} from '@/utils/converters/numberToLabel';
import {formatCurrency, getPairLabel} from '@/utils/converters/currency';
import {socketRequest} from '@/utils/socket';
import {utilTimeFormatOrMessage} from '@/utils/converters/time';

// mixins
import ExchangeMixin from '@/mixins/ExchangeMixin';
import AutoTradingMixin from '@/mixins/AutoTradingMixin';

// const
import {EXCHANGE_PRICE_BEHAVIOUR_TYPES, EXCHANGE_STATUS_TYPES} from '@/constants/common';
import {FETCH_OPEN_ORDER_LIST} from '@/constants/events/requests/actions.type';
import {CURRENCY_TYPE} from '@/constants/currencies';

// api
import RequestApi from '@/api/requestService/request.api';

// lib
import momentTz from 'moment-timezone';

// setting
const STATUSES = {
  1: 'Awaiting Trader',
  2: 'Processing',
  3: 'Accepted',
  4: 'Rejected',
  5: 'Canceled',
  6: 'Awaiting your reaction',
  7: 'Accepted by trader',
  8: 'Canceled by timeout',
};

export default {
  name: 'ExOpenOrders',
  computed: {
    CURRENCY_TYPE() {
      return CURRENCY_TYPE
    }
  },
  mixins: [ExchangeMixin, AutoTradingMixin],
  components: {ExCountdown},
  data() {
    return {
      table: [],
      isFetching: false,
      EXCHANGE_PRICE_BEHAVIOUR_TYPES,
      EXCHANGE_STATUS_TYPES,
    };
  },
  created() {
    this.fetchList();
  },
  methods: {
    orderTypeLabel,
    formatCurrency,
    utilTimeFormatOrMessage,
    getPairLabel,
    fetchList() {
      socketRequest.call(this, FETCH_OPEN_ORDER_LIST,
        (payload) => {
          this.table = payload;
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      RequestApi.fetchOpenOrderList(FETCH_OPEN_ORDER_LIST);
    },
    getPeriod(dateObj) {
      return (dateObj?.date && dateObj?.timezone)
        ? {from: Date.now(), to: momentTz.tz(dateObj.date, dateObj.timezone).valueOf()}
        : {from: 0, to: 0};
    },
    getRequestStatusName(statusId, priceBehaviourType) {
      if (priceBehaviourType === EXCHANGE_PRICE_BEHAVIOUR_TYPES.AUTO && statusId === 1) {
        return 'Awaiting Client Confirmation';
      }
      return STATUSES[statusId];
    },
    cancel(order) {
      if (!order.automatic) this.mix_exchangeMixin_cancelExchange(order.id, this.fetchList);
      if (order.automatic && order.amountFiat) this.mix_autoTradingMixin_cancelOrder(order.id, this.fetchList, true);
      if (order.automatic && order.amount) this.mix_autoTradingMixin_cancelOrder(order.id, this.fetchList);
    },
  },
};
</script>
