// component
import dashboard from '@/pages/dashboard';
import ExBalance from '@/pages/ex-balance';
import ExOrdersTabs from '@/pages/orders/ex-order-tabs';
import ExTradingContainer from '@/pages/requests/ex-trading-container';
import ExTradingMode from '@/pages/requests/ex-trading-mode';
import ExCreateNewRequest from '@/pages/requests/ex-create-new-request';
import ExDepositCurrencyTypeSelector from '@/pages/deposit/ex-deposit-currency-type-selector';
import ExDepositManualFiat from '@/pages/deposit/fiat/ex-deposit-manual-fiat';
import Transaction from '@/pages/transactions/ex-transaction';
import FundingHistory from '@/components/ex-funding-history';
import Profile from '@/pages/setting/ex-setting-tabs';
import MerchantInvoices from '@/pages/merchant/merchantInvoices';
import MerchantInvoiceView from '@/pages/merchant/merchantInvoiceView';
import MerchantInvoiceCreate from '@/pages/merchant/ex-invoice-create';
import ExGateway from '@/pages/gateway/ex-gateway';
import ExRouterWrapper from '@/components/ex-router-wrapper';
import ExWithdrawalList from '@/pages/withdrawal/ex-withdrawal-list';
// TODO Temporarily hiding fiat auto withdrawal creation form
// import ExFiatWithdrawalChoose from '@/pages/withdrawal/fiat/ex-withdrawal-fiat-choose';
// import ExWithdrawalFiat from '@/pages/withdrawal/fiat/ex-withdrawal-fiat-tabs';
import ExWithdrawalFiat from '@/pages/withdrawal/fiat/ex-withdrawal-fiat';
import ExWithdrawalCrypto from '@/pages/withdrawal/crypto/ex-withdrawal-crypto-tabs';
import ExInternalTransferCreate from '@/pages/internalTransfer/ex-internal-transfer-create';

// guard
import {accIsNotVerified, authBehavior, necessaryRole} from '@/routes/authRouters';

// const
import {ROLE_BILLING, ROLE_GENERAL, ROLE_INTERNAL_TRANSFER, ROLE_MERCHANT} from '@/constants/roles';

export default (store) => {
  return [
    {
      path: '/on-boarding',
      component: () => import('@/pages/onBoarding/ex-on-boarding'),
      name: 'OnBoarding',
      beforeEnter: accIsNotVerified(store),
    },
    {
      path: '/redirection/:path',
      component: () => import('@/components/core/ex-user-redirect'),
      name: 'UserRedirect',
      beforeEnter: authBehavior(store),
      props: true,
    },
    {
      path: '/dashboard',
      component: dashboard,
      beforeEnter: authBehavior(store),
      meta: {requiresRoleGeneral: true, requiresAuth: true},
      children: [
        {
          path: 'balances',
          component: ExBalance,
          name: 'BalancesIndex',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'profile',
          component: Profile,
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
          name: 'route-user-profile',
        },
        {
          path: 'orders/:type',
          component: ExOrdersTabs,
          name: 'Orders',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/type/:type/:currency',
          component: ExTradingMode,
          name: 'TradingMode',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/create/:requestType/:type/:currency',
          component: ExCreateNewRequest,
          name: 'RequestCreate',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/deposit/:type/:currency',
          component: ExDepositManualFiat,
          name: 'FiatDeposit',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/deposit/:currency',
          component: ExDepositCurrencyTypeSelector,
          name: 'DepositCreate',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/:id',
          component: ExTradingContainer,
          name: 'Trading',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'withdrawals',
          component: ExWithdrawalList,
          name: 'WithdrawalList',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'order/withdrawal',
          component: ExRouterWrapper,
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
          children: [
            {
              path: 'fiat',
              component: ExRouterWrapper,
              beforeEnter: necessaryRole(store, ROLE_GENERAL),
              children: [
                // TODO Temporarily hiding fiat auto withdrawal creation form
                // {
                //   path: 'choose/:currency',
                //   component: ExFiatWithdrawalChoose,
                //   name: 'WithdrawalFiatChoose',
                //   beforeEnter: necessaryRole(store, ROLE_GENERAL),
                // },
                {
                  path: ':currency',
                  component: ExWithdrawalFiat,
                  name: 'WithdrawFiat',
                  beforeEnter: necessaryRole(store, ROLE_GENERAL),
                },
              ],
            },
            {
              path: 'crypto/:type/:currency',
              component: ExWithdrawalCrypto,
              name: 'WithdrawCrypto',
              beforeEnter: necessaryRole(store, ROLE_GENERAL),
            },
          ],
        },
        {
          path: 'transactions',
          component: Transaction,
          name: 'Transaction',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'funding/:currency',
          component: FundingHistory,
          name: 'FundingHistory',
          beforeEnter: necessaryRole(store, ROLE_GENERAL),
        },
        {
          path: 'invoices',
          component: MerchantInvoices,
          name: 'MerchantInvoices',
          beforeEnter: necessaryRole(store, ROLE_MERCHANT),
        },
        {
          path: 'invoices/create',
          component: MerchantInvoiceCreate,
          name: 'MerchantInvoiceCreate',
          beforeEnter: necessaryRole(store, ROLE_MERCHANT),
        },
        {
          path: 'invoices/view/:id',
          component: MerchantInvoiceView,
          name: 'MerchantInvoiceView',
          beforeEnter: necessaryRole(store, ROLE_MERCHANT),
        },
        {
          path: 'gateway',
          component: ExRouterWrapper,
          name: 'Gateway',
          beforeEnter: necessaryRole(store, ROLE_BILLING),
          redirect: {name: 'GatewayIncoming'},
          children: [
            {
              path: 'incoming',
              component: ExGateway,
              name: 'GatewayIncoming',
              beforeEnter: necessaryRole(store, ROLE_BILLING),
            },
            {
              path: 'outgoing',
              component: ExGateway,
              name: 'GatewayOutgoing',
              beforeEnter: necessaryRole(store, ROLE_BILLING),
            },
          ],
        },
        {
          path: 'internal-transfer/create',
          component: ExInternalTransferCreate,
          name: 'InternalTransferCreate',
          beforeEnter: necessaryRole(store, ROLE_INTERNAL_TRANSFER),
        },
      ],
    },
  ];
};
